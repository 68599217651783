import React, { useEffect, useState } from 'react';
import { Modal, Box, Button, TextField, Typography, MenuItem, Dialog, Grid, IconButton, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import { addCustomer, getCustomerByContactNumber, updateCustomer } from '../../api/customer';
import { useClient } from '../../context/ClientProvider';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import ClearIcon from "@mui/icons-material/Clear";
import toast from 'react-hot-toast';

const GuestModal = ({ open, onClose, onSubmit, initialValues }) => {
    const { activeGlobalBranch } = useClient();

    const [existingGuest, setExistingGuest] = useState(null);
    const [guestDocument, setGuestDocument] = useState(null);

    const isEditMode = !!initialValues;

    const validationSchema = Yup.object({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        gender: Yup.string().required('Gender is required'),
        ageCategory: Yup.string().required('Age category is required'),
        contactNumber: Yup.string()
            .required('Contact number is required')
            .matches(/^\d{10}$/, 'Contact number must be 10 digits'),
        documentType: Yup.string().nullable(),
        // files: Yup.mixed().required('Document is required'),
    });

    const formik = useFormik({
        initialValues: {
            firstName: initialValues?.firstName || '',
            lastName: initialValues?.lastName || '',
            gender: initialValues?.gender || '',
            ageCategory: initialValues?.ageCategory || '',
            contactNumber: initialValues?.contactNumber || '',
            documentType: initialValues?.documentType || '',
            files: null,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const formData = new FormData();

            formData.append('firstName', values.firstName);
            formData.append('lastName', values.lastName);
            formData.append('contactNumber', values.contactNumber);
            formData.append('gender', values.gender);
            formData.append('ageCategory', values.ageCategory);
            formData.append('branchId', activeGlobalBranch.id);

            if (values.documentType) {
                formData.append('documentType', values.documentType);
            }

            // Append files if they exist
            if (selectedFiles && selectedFiles.length > 0) {
                selectedFiles.forEach((file, index) => {
                    formData.append('files', file);
                });
            }

            if (isEditMode) {
                const customerEditResponse = await updateCustomer(values.id || existingGuest.id, formData);
                if (customerEditResponse) {
                    onSubmit(customerEditResponse);
                }
            } else {
                // if (existingGuest) {
                //     onSubmit(existingGuest);
                // } else {
                //     if (selectedFiles.length === 0) {
                //         toast.error("Please upload document");
                //         return;
                //     }
                //     const createCustomerResponse = await addCustomer(formData);
                //     if (createCustomerResponse) {
                //         onSubmit(createCustomerResponse);
                //     }
                // }
                if (formik.values.documentType && selectedFiles.length === 0) {
                    toast.error("Please upload document");
                    return;
                }
                const createCustomerResponse = await addCustomer(formData);
                if (createCustomerResponse) {
                    onSubmit(createCustomerResponse);
                }
            }

            formik.resetForm();
            onClose();
            setExistingGuest(null);
            setSelectedFiles([]);
        },
    });

    const getExistingGuest = async (contactNumber) => {
        const existingGuestResponse = await getCustomerByContactNumber(
            activeGlobalBranch?.id,
            contactNumber
        );
        if (existingGuestResponse.length > 0) {
            setExistingGuest(existingGuestResponse[0]);
            formik.setFieldValue("firstName", existingGuestResponse[0]?.firstName);
            formik.setFieldValue("lastName", existingGuestResponse[0]?.lastName);
            formik.setFieldValue("gender", existingGuestResponse[0]?.gender);
            formik.setFieldValue("ageCategory", existingGuestResponse[0]?.ageCategory);
            formik.setFieldValue("documentType", existingGuestResponse[0]?.documentType);
            setGuestDocument(existingGuestResponse[0]?.imageUrl);
        } else {
            setExistingGuest(null);
            formik.setFieldValue("firstName", "");
            formik.setFieldValue("lastName", "");
            formik.setFieldValue("gender", "");
            formik.setFieldValue("ageCategory", "");
            formik.setFieldValue("documentType", null);
            setGuestDocument(null);
        }
    };

    // handle upload document file
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileKey, setFileKey] = useState(Date.now());
    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 3) {
            toast.error("File should not be more than 3")
            return;
        }
        if (files.some(file => !['image/png', 'image/jpeg', 'image/jpg', 'image/gif'].includes(file.type))) {
            toast.error("Invalid file type. Only PNG, JPEG, JPG and GIF files are allowed.");
            return;
        }

        setSelectedFiles(files);
    };

    const removeFile = (fileToRemove) => {
        setSelectedFiles(prevFiles => prevFiles.filter(file => file !== fileToRemove));
    };

    const viewFile = (file) => {
        window.open(URL.createObjectURL(file), "_blank");
    };

    useEffect(() => {
        formik.setValues(initialValues || {
            firstName: '',
            lastName: '',
            gender: '',
            ageCategory: '',
            contactNumber: '',
            documentType: '',
            // files: null,
        });
    }, [initialValues]);

    return (
        <Dialog open={open} onClose={() => { onClose(); setGuestDocument(null); formik.resetForm(); setExistingGuest(null); setSelectedFiles([]) }}>
            <Box sx={{
                width: 400,
                bgcolor: 'background.paper',
                p: 2,
                borderRadius: 2,
                boxShadow: 24,
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography variant="body1" mb={2} fontWeight={600}>
                    {initialValues ? 'Edit Guest' : 'Add Guest'}
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                label="Contact Number *"
                                name="contactNumber"
                                type='number'
                                value={formik.values.contactNumber}
                                // onChange={formik.handleChange}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    const enteredNumber = e.target.value;
                                    if (enteredNumber.length === 10) {
                                        getExistingGuest(enteredNumber);
                                    }
                                }}
                                onBlur={formik.handleBlur}
                                error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
                            // helperText={formik.touched.contactNumber && formik.errors.contactNumber}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                label="First Name *"
                                name="firstName"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            // helperText={formik.touched.firstName && formik.errors.firstName}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                label="Last Name *"
                                name="lastName"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                            // helperText={formik.touched.lastName && formik.errors.lastName}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Typography variant="body2">Age Group *</Typography>
                            <RadioGroup
                                row
                                name="ageCategory"
                                value={formik.values.ageCategory}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                <FormControlLabel value="adult" control={<Radio size='small' />} label="Adult" />
                                <FormControlLabel value="child" control={<Radio size='small' />} label="Child" />
                            </RadioGroup>
                            {formik.touched.ageCategory && Boolean(formik.errors.ageCategory) && (
                                <Typography color="error">{formik.errors.ageCategory}</Typography>
                            )}
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Typography variant="body2">Gender *</Typography>
                            <RadioGroup
                                row
                                name="gender"
                                value={formik.values.gender}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                <FormControlLabel value="male" control={<Radio size='small' />} label="Male" />
                                <FormControlLabel value="female" control={<Radio size='small' />} label="Female" />
                                <FormControlLabel value="other" control={<Radio size='small' />} label="Other" />
                            </RadioGroup>
                            {formik.touched.gender && Boolean(formik.errors.gender) && (
                                <Typography color="error">{formik.errors.gender}</Typography>
                            )}
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                label="Document Type"
                                name="documentType"
                                select
                                value={formik.values.documentType}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.documentType && Boolean(formik.errors.documentType)}
                            >
                                <MenuItem value="addhar">Aadhaar</MenuItem>
                                <MenuItem value="votor">Voter ID</MenuItem>
                            </TextField>
                        </Grid>

                        {guestDocument ? (
                            <Grid item xs={12} sm={6}>
                                <Button
                                    component="label"
                                    variant="outlined"
                                    startIcon={<FileOpenIcon />}
                                    sx={{ fontSize: 12 }}
                                    onClick={() => {
                                        const documentUrl = `${process.env.REACT_APP_API_BASE_IMAGE_URL}/customer/${guestDocument}`;
                                        window.open(documentUrl, "_blank");
                                    }}
                                >
                                    View Document
                                </Button>
                            </Grid>
                        ) : null}

                        <Grid item xs={12} sm={12} display="flex" direction="column">
                            <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                                <Button
                                    component="label"
                                    variant="outlined"
                                    startIcon={<CloudUploadIcon />}
                                    sx={{ fontSize: 12 }}
                                >
                                    {`Upload${" "}Document`}
                                    <input
                                        key={fileKey}
                                        className="customer-file-upload-input"
                                        type="file"
                                        multiple
                                        onChange={handleFileChange}
                                    />
                                </Button>
                                {selectedFiles.length > 0 && (
                                    <div style={{ marginTop: 10 }}>
                                        {selectedFiles.map((file, index) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                                                <IconButton
                                                    sx={{ position: 'relative' }}
                                                    size="small"
                                                    color="error"
                                                    onClick={() => { removeFile(file); setFileKey(Date.now()) }}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                                <Button
                                                    size="small"
                                                    variant="text"
                                                    sx={{ ml: 2 }}
                                                    onClick={() => viewFile(file)}
                                                >
                                                    View
                                                </Button>
                                                <Typography sx={{ display: 'inline-block', ml: 1 }}>
                                                    {file.name}
                                                </Typography>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Box>
                            <Typography mb={2} variant="caption" color="primary">document should be only in png/jpg/jpeg/gif format</Typography>
                        </Grid>
                    </Grid>
                    <Button type="submit" variant="contained">
                        {initialValues ? 'Update Guest' : 'Add Guest'}
                    </Button>
                </form>
            </Box>
        </Dialog>
    );
};

export default GuestModal;
