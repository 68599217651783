import React, { createContext, useContext, useEffect, useState } from 'react';
import { getBranchByClient } from '../api/branch';
import LoadingScreen from '../components/LoadingScreen';
import Loader from '../components/Loader';

const ClientContext = createContext();

const ClientProvider = ({ children }) => {
    const userType = JSON.parse(localStorage.getItem('userType'));
    const [globalLoading, setGlobalLoading] = useState(false);
    const [globalClientList, setGlobalClientList] = useState([]);
    const [activeGlobalClient, setActiveGlobalClient] = useState(null);
    const [globalBranchList, setGlobalBranchList] = useState([]);
    const [activeGlobalBranch, setActiveGlobalBranch] = useState(null);

    const clientList = JSON.parse(localStorage.getItem('globalClientList')) || [];
    const branchList = JSON.parse(localStorage.getItem('globalBranchList')) || [];
    const activeClient = JSON.parse(localStorage.getItem('activeGlobalClient')) || null;
    const activeBranch = JSON.parse(localStorage.getItem('activeGlobalBranch')) || null;
    useEffect(() => {
        if (userType === 'super_admin') {
            setGlobalLoading(true);
            setGlobalClientList(clientList);
            setGlobalBranchList(branchList);
            setActiveGlobalClient(activeClient);
            setActiveGlobalBranch(activeBranch);
            setGlobalLoading(false);
        } else if ((userType === 'admin' && activeClient && branchList && activeBranch)) {
            setGlobalLoading(true);
            setActiveGlobalClient(activeClient);
            setGlobalBranchList(branchList);
            setActiveGlobalBranch(activeBranch);
            setGlobalLoading(false);
        } else if ((userType === 'supervisor' || userType === 'frontdesk' && activeClient && activeBranch)) {
            setGlobalLoading(true);
            setActiveGlobalClient(activeClient);
            setActiveGlobalBranch(activeBranch);
            setGlobalLoading(false);
        }
    }, []);

    if (globalLoading) {
        return <Loader />;
    }

    const updateActiveGlobalClient = async (client) => {
        localStorage.setItem("activeGlobalClient", JSON.stringify(client));
        setActiveGlobalClient(client);
        // fetch branch by client
        const branchResponse = await getBranchByClient(client.id);
        localStorage.setItem("globalBranchList", JSON.stringify(branchResponse));
        setGlobalBranchList(branchResponse);
        // set first branch from branch list when a client change
        branchResponse.length > 0 ? localStorage.setItem("activeGlobalBranch", JSON.stringify(branchResponse[0])) : localStorage.setItem("activeGlobalBranch", null);
        branchResponse.length > 0 ? setActiveGlobalBranch(branchResponse[0]) : setActiveGlobalBranch(null);
    };

    const updateActiveGlobalBranch = async (branch) => {
        localStorage.setItem("activeGlobalBranch", JSON.stringify(branch));
        setActiveGlobalBranch(branch);
    };

    return (
        <ClientContext.Provider
            value={{
                globalClientList,
                setGlobalClientList,
                globalBranchList,
                setGlobalBranchList,
                activeGlobalClient,
                setActiveGlobalClient,
                activeGlobalBranch,
                setActiveGlobalBranch,
                updateActiveGlobalClient,
                updateActiveGlobalBranch
            }}>
            {children}
        </ClientContext.Provider>
    )
}

export const useClient = () => {
    return useContext(ClientContext);
}

export default ClientProvider;