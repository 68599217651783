import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import account from '../../../_mock/account';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../../context/AuthProvider';
import { useClient } from '../../../context/ClientProvider';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    path: 'dashboard'
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    path: 'profile',
  },
  {
    label: 'Change Password',
    icon: 'eva:settings-2-fill',
    path: 'change-password',
  },
  // {
  //   label: 'Settings',
  //   icon: 'eva:settings-2-fill',
  //   path: 'settings',
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { globalUser, setGlobalUser } = useAuth();
  const { setGlobalClientList, setGlobalBranchList, setActiveGlobalClient, setActiveGlobalBranch } = useClient();
  const localUserType = localStorage.getItem('userType');
  const user = JSON.parse(localStorage.getItem("user"));
  const userType = JSON.parse(localUserType);
  const navigate = useNavigate()
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const logout = () => {
    localStorage.clear();
    userType === "admin" || userType === "supervisor" || userType === "frontdesk" ? navigate('/') : navigate('/superadmin');
    setGlobalUser(
      {
        status: false,
        superAdminDetail: null,
        adminDetail: null,
      }
    );
    setGlobalClientList([]);
    setActiveGlobalClient(null);
    setGlobalBranchList([]);
    setActiveGlobalBranch(null);
  }

  const imageUrl = user?.userType === "super_admin" ?
    `${process.env.REACT_APP_API_BASE_IMAGE_URL}/superAdmin/${user?.superAdmin?.image}`
    : `${process.env.REACT_APP_API_BASE_IMAGE_URL}/user/${user?.user?.image}`;

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={imageUrl} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.user?.firstName || user?.superAdmin?.firstName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.user?.email || user?.superAdmin?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} component={Link} to={option.path} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem onClick={logout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}