import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const CheckoutNowConfirmationDialog = ({ open, onClose, onConfirm }) => {
    return (
        <>
            <Dialog
                open={open}
                onClose={() => onClose(false)}
            >
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to proceed with checkout?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose(false)} color="secondary">
                        No
                    </Button>
                    <Button onClick={onConfirm} variant="contained" color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CheckoutNowConfirmationDialog;