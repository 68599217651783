import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import palette from "../../theme/palette";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  DateTimePicker,
  MobileDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Iconify from "../iconify/Iconify";
import deleteIcon from "../../assets/icons/trash.png";
import { useIsSmallScreen } from "../../hooks/useResponsive";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { getRoomAvailability } from "../../api/rooms";
import GuestModal from "../CustomerCheckoutComponent/GuestModal";
import { Edit } from "@mui/icons-material";

const CheckoutDrawer = ({
  checkoutDrawerOpen,
  handleCheckoutDrawerClose,
  formik,
  getExistingCustomer,
  handleCheckoutDateChange,
  editCustomer,
  actionMode,
  selectedFiles,
  setSelectedFiles,
  fileKey,
  setFileKey,
  handleFileChange,
  removeFile,
  viewFile,
  customerDocument,
  activeGlobalBranch,
}) => {
  const isSmallScreen = useIsSmallScreen();
  const [allAvailableRooms, setAllAvailableRooms] = useState([]);
  const [guestModalOpen, setGuestModalOpen] = useState(false);
  const [currentRoomIndex, setCurrentRoomIndex] = useState(null);
  const [guestToEdit, setGuestToEdit] = useState(null);

  const getAllAvailableRooms = async (checkInDate, checkOutDate) => {
    if (checkInDate && checkOutDate
      && moment(checkInDate).isValid() && moment(checkOutDate).isValid()
      // && moment(checkInDate).format('HH:mm') !== '00:00'
      // && moment(checkOutDate).format('HH:mm') !== '00:00'
      // && moment(checkInDate).format('mm') !== '00'
      // && moment(checkOutDate).format('mm') !== '00'
    ) {

      const response = await getRoomAvailability(
        activeGlobalBranch?.id,
        moment(checkInDate, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DDTHH:mm[Z]'),
        moment(checkOutDate, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DDTHH:mm[Z]')
      );
      setAllAvailableRooms(response);
    }
  }

  const handleAddRoom = () => {
    const newRoom = {
      roomId: "",
      checkInDate: moment(),
      checkOutDate: moment(),
      guests: [],
    };
    formik.setFieldValue("rooms", [...formik.values.rooms, newRoom]);
  };
  const handleRemoveRoom = (index) => {
    if (formik.values.rooms.length > 1) {
      const updatedRooms = formik.values.rooms.filter((_, i) => i !== index);
      formik.setFieldValue("rooms", updatedRooms);
    }
  };

  const handleAddGuest = (roomIndex) => {
    setCurrentRoomIndex(roomIndex);
    setGuestToEdit(null);
    setGuestModalOpen(true);
  };

  const handleEditGuest = (guest, roomIndex) => {
    setGuestModalOpen(true);
    setGuestToEdit(guest);
    setCurrentRoomIndex(roomIndex);
  };

  const handleGuestSubmit = (values) => {
    // console.log("guest values:", values);
    const updatedRooms = [...formik.values.rooms];

    // Ensure the guests array exists for the current room
    if (!updatedRooms[currentRoomIndex].guests) {
      updatedRooms[currentRoomIndex].guests = [];
    }

    if (guestToEdit) {
      // Find the index of the guest to update
      const guestIndex = updatedRooms[currentRoomIndex].guests.findIndex(
        guest => guest.contactNumber === guestToEdit.contactNumber &&
          guest.firstName === guestToEdit.firstName &&
          guest.lastName === guestToEdit.lastName
      );

      if (guestIndex !== -1) {
        // Update the existing guest
        updatedRooms[currentRoomIndex].guests[guestIndex] = {
          ...updatedRooms[currentRoomIndex].guests[guestIndex],
          ...values,
        };
      }
    } else {
      // Add a new guest
      updatedRooms[currentRoomIndex].guests.push({ id: Date.now(), ...values });
    }

    formik.setFieldValue('rooms', updatedRooms);
    setGuestModalOpen(false);
    handleCloseModal();
    // const {values: formikValues} = formik;
    // console.log("formik.get values", formikValues);
  };

  const handleRemoveGuest = (roomIndex, guestIndex) => {
    const updatedRooms = [...formik.values.rooms];
    updatedRooms[roomIndex].guests.splice(guestIndex, 1);
    formik.setFieldValue('rooms', updatedRooms);
  };

  const handleCloseModal = () => {
    setGuestToEdit(null);
    setGuestModalOpen(false);
  };

  useEffect(() => {
    getAllAvailableRooms(formik.values.rooms[0]?.checkInDate, formik.values.rooms[0]?.checkOutDate);

  }, [formik.values.rooms[0]?.checkInDate, formik.values.rooms[0]?.checkOutDate]);

  return (
    <>
      <Drawer
        anchor="right"
        open={checkoutDrawerOpen}
        onClose={handleCheckoutDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: 500,
            ...(isSmallScreen && { width: 320 }),
          },
        }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              bgcolor: "background.paper",
              // width: 400,
              marginY: 2,
            }}
          >
            <IconButton
              sx={{ position: "absolute", top: 5, right: 5 }}
              color={palette.grey[500]}
              onClick={handleCheckoutDrawerClose}
            >
              <ClearIcon />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 0,
              }}
            >
              <Typography variant="h6" gutterBottom mb={2}>
                {actionMode === "add" ? `Add Check-in` : `Edit Check-out`}
              </Typography>
            </Box>
          </Box>

          <form onSubmit={formik.handleSubmit}>
            {actionMode === "add" && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Contact Number *"
                    name="customerContactNumber"
                    type="number"
                    onChange={(e) => {
                      formik.handleChange(e);
                      const enteredNumber = e.target.value;
                      if (enteredNumber.length === 10) {
                        getExistingCustomer(enteredNumber);
                      }
                    }}
                  />
                  {formik.touched.customerContactNumber &&
                    formik.errors.customerContactNumber ? (
                    <Typography
                      sx={{ color: "red", variant: "body2", fontSize: "12px" }}
                    >
                      {formik.errors.customerContactNumber}
                    </Typography>
                  ) : null}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="First Name *"
                    name="customerFirstName"
                    type="text"
                    {...formik.getFieldProps("customerFirstName")}
                  />
                  {formik.touched.customerFirstName &&
                    formik.errors.customerFirstName ? (
                    <Typography
                      sx={{ color: "red", variant: "body2", fontSize: "12px" }}
                    >
                      {formik.errors.customerFirstName}
                    </Typography>
                  ) : null}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Last Name *"
                    name="customerLastName"
                    type="text"
                    {...formik.getFieldProps("customerLastName")}
                  />
                  {formik.touched.customerLastName &&
                    formik.errors.customerLastName ? (
                    <Typography
                      sx={{ color: "red", variant: "body2", fontSize: "12px" }}
                    >
                      {formik.errors.customerLastName}
                    </Typography>
                  ) : null}
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography variant="body2">Age Group *</Typography>
                  <RadioGroup
                    row
                    name="customerAgeGroup"
                    value={formik.values.customerAgeGroup}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <FormControlLabel value="adult" control={<Radio size='small' />} label="Adult" />
                    <FormControlLabel value="child" control={<Radio size='small' />} label="Child" />
                  </RadioGroup>
                  {formik.touched.customerAgeGroup &&
                    formik.errors.customerAgeGroup ? (
                    <Typography
                      sx={{ color: "red", variant: "body2", fontSize: "12px" }}
                    >
                      {formik.errors.customerAgeGroup}
                    </Typography>
                  ) : null}
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography variant="body2">Gender *</Typography>
                  <RadioGroup
                    row
                    name="customerGender"
                    value={formik.values.customerGender}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <FormControlLabel value="male" control={<Radio size='small' />} label="Male" />
                    <FormControlLabel value="female" control={<Radio size='small' />} label="Female" />
                    <FormControlLabel value="other" control={<Radio size='small' />} label="Other" />
                  </RadioGroup>
                  {formik.touched.customerGender &&
                    formik.errors.customerGender ? (
                    <Typography
                      sx={{ color: "red", variant: "body2", fontSize: "12px" }}
                    >
                      {formik.errors.customerGender}
                    </Typography>
                  ) : null}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Address *"
                    name="customerAddress"
                    multiline
                    rows={2}
                    {...formik.getFieldProps("customerAddress")}
                  />
                  {formik.touched.customerAddress &&
                    formik.errors.customerAddress ? (
                    <Typography
                      sx={{ color: "red", variant: "body2", fontSize: "12px" }}
                    >
                      {formik.errors.customerAddress}
                    </Typography>
                  ) : null}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Purpose of Visit *"
                    name="customerPurposeOfVisit"
                    type="text"
                    {...formik.getFieldProps("customerPurposeOfVisit")}
                  />
                  {formik.touched.customerPurposeOfVisit &&
                    formik.errors.customerPurposeOfVisit ? (
                    <Typography
                      sx={{ color: "red", variant: "body2", fontSize: "12px" }}
                    >
                      {formik.errors.customerPurposeOfVisit}
                    </Typography>
                  ) : null}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Arrival From *"
                    name="customerArrivalFrom"
                    type="text"
                    {...formik.getFieldProps("customerArrivalFrom")}
                  />
                  {formik.touched.customerArrivalFrom &&
                    formik.errors.customerArrivalFrom ? (
                    <Typography
                      sx={{ color: "red", variant: "body2", fontSize: "12px" }}
                    >
                      {formik.errors.customerArrivalFrom}
                    </Typography>
                  ) : null}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Document Type"
                    name="customerDocumentType"
                    select
                    value={formik.values.customerDocumentType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value={"addhar"}>Adhar</MenuItem>
                    <MenuItem value={"votor"}>Votor</MenuItem>
                  </TextField>
                  {formik.touched.customerDocumentType &&
                    formik.errors.customerDocumentType ? (
                    <Typography
                      sx={{ color: "red", variant: "body2", fontSize: "12px" }}
                    >
                      {formik.errors.customerDocumentType}
                    </Typography>
                  ) : null}
                </Grid>

                {customerDocument ? (
                  <Grid item xs={12} sm={6}>
                    <Button
                      component="label"
                      variant="outlined"
                      startIcon={<FileOpenIcon />}
                      sx={{ fontSize: 12 }}
                      onClick={() => {
                        const documentUrl = `${process.env.REACT_APP_API_BASE_IMAGE_URL}/customer/${customerDocument}`;
                        window.open(documentUrl, "_blank");
                      }}
                    >
                      View Document
                    </Button>
                  </Grid>
                ) : null}

                <Grid item xs={12} sm={12} display="flex" direction="column">
                  <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                    <Button
                      component="label"
                      variant="outlined"
                      startIcon={<CloudUploadIcon />}
                      sx={{ fontSize: 12 }}
                    >
                      {`Upload${" "}Document`}
                      <input
                        key={fileKey}
                        className="customer-file-upload-input"
                        type="file"
                        multiple
                        onChange={handleFileChange}
                      />
                    </Button>
                    {selectedFiles.length > 0 && (
                      <div style={{ marginTop: 10 }}>
                        {selectedFiles.map((file, index) => (
                          <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                            <IconButton
                              sx={{ position: 'relative' }}
                              size="small"
                              color="error"
                              onClick={() => { removeFile(file); setFileKey(Date.now()) }}
                            >
                              <ClearIcon />
                            </IconButton>
                            <Button
                              size="small"
                              variant="text"
                              sx={{ ml: 2 }}
                              onClick={() => viewFile(file)}
                            >
                              View
                            </Button>
                            <Typography sx={{ display: 'inline-block', ml: 1 }}>
                              {file.name}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    )}
                  </Box>
                  <br />
                  <Typography variant="caption" color="primary">document should be only in png/jpg/jpeg/gif format</Typography>
                </Grid>

                {/* Additional inputs */}
                {formik.values.rooms.map((room, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    width={"100%"}
                    mt={2}
                  >
                    <Grid
                      sx={{
                        marginLeft: 2,
                        borderRadius: 1,
                        paddingRight: 1,
                        paddingBottom: 1,
                        paddingLeft: "0px !important",
                        paddingTop: "0px !important",
                      }}
                      item
                      container
                      spacing={1}
                      border="1px solid #DC419C"
                    >
                      {/* DateTimePicker for check-in */}
                      <Grid item xs={6} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <MobileDateTimePicker
                            label="Check-in *"
                            value={room.checkInDate}
                            onChange={(value) =>
                              formik.setFieldValue(`rooms[${index}].checkInDate`, value)
                            }
                            minDateTime={moment().startOf("day")}
                            sx={{ width: "100%" }}
                          />
                        </LocalizationProvider>
                        {/* Error message for check-in date */}
                        {formik.touched.rooms && formik.errors.rooms?.[index]?.checkInDate && (
                          <Typography sx={{ color: "red", variant: "body2", fontSize: "12px" }}>
                            {formik.errors.rooms[index].checkInDate}
                          </Typography>
                        )}
                      </Grid>
                      {/* DateTimePicker for check-out */}
                      <Grid item xs={6} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <MobileDateTimePicker
                            label="Check-out *"
                            value={room.checkOutDate}
                            onChange={(value) =>
                              formik.setFieldValue(`rooms[${index}].checkOutDate`, value)
                            }
                            minDateTime={moment().startOf("day")}
                            sx={{ width: "100%" }}
                          />
                        </LocalizationProvider>
                        {/* Error message for check-out date */}
                        {formik.touched.rooms && formik.errors.rooms?.[index]?.checkOutDate && (
                          <Typography sx={{ color: "red", variant: "body2", fontSize: "12px" }}>
                            {formik.errors.rooms[index].checkOutDate}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        {/* Autocomplete for room selection */}
                        <Autocomplete
                          options={allAvailableRooms}
                          getOptionLabel={(option) => option.name}
                          value={
                            allAvailableRooms.find((option) => option.id === room.roomId) || null
                          }
                          onChange={(event, newValue) =>
                            formik.setFieldValue(`rooms[${index}].roomId`, newValue?.id || "")
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="Rooms *" />
                          )}
                          renderOption={(props, option) => (
                            <div
                              {...props}
                              style={{
                                backgroundColor:
                                  option?.customercheckout?.length >= 1 &&
                                  "#fcd4db",
                              }}
                            >
                              {option.name}
                            </div>
                          )}
                        />

                        {/* Error message for room selection */}
                        {formik.touched.rooms && formik.errors.rooms?.[index]?.roomId && (
                          <Typography sx={{ color: "red", variant: "body2", fontSize: "12px" }}>
                            {formik.errors.rooms[index].roomId}
                          </Typography>
                        )}
                      </Grid>

                      {room.guests?.map((guest, guestIndex) => (
                        <Box key={guestIndex} sx={{ ml: 1, mt: 1, width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <Box>
                            <Typography variant="body1">{guest.firstName} {guest.lastName}</Typography>
                          </Box>
                          <Box>
                            <IconButton size="small" onClick={() => handleRemoveGuest(index, guestIndex)}>
                              <DeleteIcon />
                            </IconButton>
                            <IconButton size="small" onClick={() => handleEditGuest(guest, index)}>
                              <EditIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      ))}

                      <Grid item xs={12} sm={12}>
                        <Button variant="outlined" size="small" onClick={() => handleAddGuest(index)}>Add Guest</Button>
                      </Grid>

                      {/* Remove Room button */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        {formik.values.rooms.length > 1 || index !== 0 ? (
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => handleRemoveRoom(index)}
                            sx={{ height: 35, width: 35 }}
                          >
                            <img src={deleteIcon} alt="Delete Room" />
                          </IconButton>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Box>
                ))}

                <Grid item xs={12} sm={12}>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={handleAddRoom}
                    sx={{ ml: 1 }}
                  >
                    Add Room
                  </Button>
                </Grid>
              </Grid>
            )}

            {actionMode === "edit" && (
              <Grid item xs={12} sm={12}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MobileDateTimePicker
                    label="CheckOut"
                    value={moment.utc(editCustomer?.checkOut)}
                    onChange={(newDate) => handleCheckoutDateChange(newDate)}
                    minDateTime={moment().startOf("day")}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>
                {/* {formik.touched.checkOutDate && formik.errors.checkOutDate ? (
                                            <Typography sx={{ color: 'red', variant: 'body2', fontSize: '12px' }}>{formik.errors.checkOutDate}</Typography>
                                        ) : null} */}
              </Grid>
            )}

            <Box my={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="submit" variant="contained" color="primary">
                {actionMode === "add" ? "Add Check-in" : "Update Check-out"}
              </Button>
              {actionMode === "add" && (
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  sx={{
                    backgroundColor: palette.grey[500],
                    "&:hover": {
                      backgroundColor: palette.grey[600],
                    },
                    marginLeft: 1,
                  }}
                  onClick={() => formik.resetForm()}
                >
                  clear
                </Button>
              )}
            </Box>
          </form>
        </Container>
      </Drawer>

      <GuestModal
        open={guestModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleGuestSubmit}
        initialValues={guestToEdit}
      />
    </>
  );
};

export default CheckoutDrawer;
