import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  IconButton,
  Stack,
  Popover,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import GroupIcon from '@mui/icons-material/Group';
import { DataGrid } from "@mui/x-data-grid";
import palette from "../../theme/palette";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import moment from "moment";
import { Icon } from "@iconify/react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import iconProfile from "../../assets/icons/Profile ioc.png";

// apis
import {
  createCheckout,
  findAllCheckoutByBranch,
  updateCheckout,
} from "../../api/checkout";
import { getCustomerByContactNumber, resendOtp } from "../../api/customer";
// context
import { useClient } from "../../context/ClientProvider";
// components
import CustomTooltip from "../../components/common/CustomTooltip";
import ServerPagination from "../../components/ServerPagination";
import CheckoutDrawer from "../../components/Drawers/CheckoutDrawer";
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import PageHeader from "../../components/common/PageHeader";
import CheckoutDataFilter from "./CheckoutDataFilter";
// import EditIcon from "../../assets/icons/EditIcon";
import EditIcon from '@mui/icons-material/Edit';
import Loader from "../../components/Loader";
import OtpModal from "../../components/CustomerCheckoutComponent/OtpModal";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import GuestInfoModal from "../../components/CustomerCheckoutComponent/GuestInfoModal";
import CheckoutNowConfirmationDialog from "../../components/CustomerCheckoutComponent/CheckoutNowConfirmationDialog";

const customerCheckoutValidationSchema = Yup.object().shape({
  customerContactNumber: Yup.string()
    .required("Contact is required")
    .matches(/^[0-9]+$/, "Must be a valid number")
    .min(10, "Must be at least 10 digits")
    .max(10, "Must not exceed 10 digits"),
  customerFirstName: Yup.string().required("First Name is required"),
  customerLastName: Yup.string().required("Last Name is required"),
  customerAgeGroup: Yup.string().required("Age Group is required"),
  customerGender: Yup.string().required("Gender is required"),
  customerAddress: Yup.string().required("Address is required"),
  customerPurposeOfVisit: Yup.string().required("Purpose of visit is required"),
  customerArrivalFrom: Yup.string().required("Arrival from is required"),
  // customerDocumentType: Yup.string().required("Document Type is required"),
  rooms: Yup.array()
    .of(
      Yup.object().shape({
        roomId: Yup.string().required("Room is required"),
        checkInDate: Yup.date().required("Check-in date is required"),
        checkOutDate: Yup.date().required("Check-out date is required"),
      })
    )
    .min(1, "At least one room must be added"),
});

const CustomerCheckout = () => {
  const userType = JSON.parse(localStorage.getItem("userType"));
  const { activeGlobalBranch } = useClient();
  // states----------------------------------------------------------------
  // state pagination & filter------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalSize, setTotalSize] = useState(1);
  const [loading, setLoading] = useState(true);
  const [customerDocument, setCustomerDocument] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  // state customer-checkout --------------------
  const [customerCheckout, setCustomerCheckout] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [actionMode, setActionMode] = useState("add");
  const [existingCustomer, setExistingCustomer] = useState(null);
  const [editCustomer, setEditCustomer] = useState(null);

  //get existing customer by contact number
  const getExistingCustomer = async (contactNumber) => {
    const response = await getCustomerByContactNumber(
      activeGlobalBranch?.id,
      contactNumber
    );
    // console.log("res:::", response[0]);
    if (response.length > 0) {
      setExistingCustomer(response[0]);
      formik.setFieldValue("customerFirstName", response[0]?.firstName);
      formik.setFieldValue("customerLastName", response[0]?.lastName);
      formik.setFieldValue("customerAgeGroup", response[0]?.ageCategory);
      formik.setFieldValue("customerGender", response[0]?.gender);
      // formik.setFieldValue("customerDocumentType", response[0]?.documentType);
      formik.setFieldValue("customerIsActive", response[0]?.isActive);
      setCustomerDocument(response[0]?.imageUrl);
    } else {
      setExistingCustomer(null);
      setCustomerDocument(null);
      formik.setFieldValue("customerFirstName", "");
      formik.setFieldValue("customerLastName", "");
      formik.setFieldValue("customerDocumentType", "");
      formik.setFieldValue("customerIsActive", true);
      formik.setFieldValue("customerAgeGroup", "");
      formik.setFieldValue("customerGender", "");
    }
  };

  const getCheckouts = async (isExport) => {
    setLoading(true);
    try {
      const response = await findAllCheckoutByBranch({
        branchId: activeGlobalBranch.id,
        currentPage: currentPage,
        pageSize: pageSize,
        startDate: startDate
          ? moment(startDate).format("YYYY-MM-DD 00:00:00")
          : startDate,
        endDate: endDate
          ? moment(endDate).format("YYYY-MM-DD 23:59:59")
          : endDate,
        column,
        direction,
        isExport: isExport,
      });
      if (response) {
        const { data, totalPage, totalSize, key } = response;
        setCustomerCheckout(data);
        setTotalPages(totalPage);
        setTotalSize(totalSize);
        setLoading(false);
        if (isExport) {
          return key;
        }
      }
    } catch (error) {
      // console.log("get customer-checkout by branch error", error);
    }
  };

  const handleExport = async () => {
    if (startDate && endDate) {
      try {
        const downloadUrl = await getCheckouts(true);
        setDownloadUrl(downloadUrl);
        if (downloadUrl) {
          const link = document.createElement("a");
          link.href = `${process.env.REACT_APP_API_BASE_IMAGE_URL}/${downloadUrl}`;
          link.setAttribute("download", ""); // Force download attribute
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Please enter dates");
    }
  };

  // edit checkout
  const editCheckout = async (status) => {
    try {
      // moment(editCustomer.checkOut, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
      const response = await updateCheckout(editCustomer.id, {
        checkOut: status === "now" ? moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') : moment(editCustomer.checkOut, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      });
      if (response) {
        setCustomerCheckout((prevCheckout) =>
          prevCheckout.map((chkout) =>
            chkout.id === editCustomer.id
              ? { ...editCustomer, checkOut: response?.checkOut }
              : chkout
          )
        );
        handleCloseConfirmation();
        handleCheckoutDrawerClose();
        toast.success("checkout updated successfully");
      }
    } catch (error) {
      if (error.response?.data?.statusCode === 400) {
        toast.error(error.response?.data?.message?.message[0]);
      } else if (error.response?.data?.statusCode === 409) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error(error.response?.data?.message);
      }
    }
  };

  // checkout filter
  /*  const [startDate, setStartDate] = useState(null);
   const [endDate, setEndDate] = useState(null); */
  const [startDate, setStartDate] = useState(
    moment().subtract(7, "days").startOf("day")
  );
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false);

  useEffect(() => {
    getCheckouts();
  }, [activeGlobalBranch, currentPage, pageSize, endDate, column, direction]);

  useEffect(() => {
    if (!activeGlobalBranch) {
      setLoading(false);
    }
  }, [activeGlobalBranch]);

  const customerCheckoutColumns = [
    {
      field: "firstName",
      headerName: "First Name",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <CustomTooltip title={row.customer?.firstName}>
            {row.customer?.firstName}
          </CustomTooltip>
        );
      },
    },
    {
      field: "lastName",
      headerName: "Last Name",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <CustomTooltip title={row.customer?.lastName}>
            {row.customer?.lastName}
          </CustomTooltip>
        );
      },
    },
    {
      field: "contactNumber",
      headerName: "Contact Number",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <CustomTooltip title={row.customer?.contactNumber}>
            {row.customer?.contactNumber}
          </CustomTooltip>
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <CustomTooltip title={row?.address}>{row?.address}</CustomTooltip>
        );
      },
    },
    {
      field: "purposeOfVisit",
      headerName: "Purpose Of Visit",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <CustomTooltip title={row?.purposeOfVisit}>
            {row?.purposeOfVisit}
          </CustomTooltip>
        );
      },
    },
    {
      field: "arrivalFrom",
      headerName: "Arrival From",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <CustomTooltip title={row?.arrivalFrom}>
            {row?.arrivalFrom}
          </CustomTooltip>
        );
      },
    },
    {
      field: "room",
      headerName: "Room Name",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <CustomTooltip title={row.room?.name}>{row.room?.name}</CustomTooltip>
        );
      },
    },
    {
      field: "checkIn",
      headerName: "Check-in Date",
      flex: 1.5,
      sortable: true,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <CustomTooltip
            title={moment(row.checkIn, "YYYY-MM-DD HH:mm").format(
              "Do MMM YYYY, h:mm a"
            )}
          >
            {moment(row.checkIn, "YYYY-MM-DD HH:mm:ss").format(
              "Do MMM YYYY, h:mm a"
            )}
          </CustomTooltip>
        );
      },
    },
    {
      field: "checkOut",
      headerName: "Check-out Date",
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: ({ row }) => {
        return (
          <CustomTooltip
            title={moment(row.checkOut, "YYYY-MM-DD HH:mm").format(
              "Do MMM YYYY, h:mm a"
            )}
          >
            {moment(row.checkOut, "YYYY-MM-DD HH:mm").format(
              "Do MMM YYYY, h:mm a"
            )}
          </CustomTooltip>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      // flex: 0.5,
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={0}>
            <CustomTooltip title="view Guests">
              <IconButton
                size="small"
                color="primary"
                disabled={row?.customerCheckoutJunction?.length === 0}
                onClick={() => openGuestModal(row)}
              >
                <GroupIcon fontSize="small" />
              </IconButton>
            </CustomTooltip>
            {/* Checkout Icon */}
            <CustomTooltip title="Checkout">
              <IconButton
                size="small"
                color="primary"
                disabled={row.checkOut < moment().format('YYYY-MM-DD HH:mm:ss')}
                onClick={() => { handleCheckoutNow(row) }}
              >
                <ExitToAppIcon fontSize="small" />
              </IconButton>
            </CustomTooltip>
            {/* Edit Icon */}
            <CustomTooltip title="Edit">
              <IconButton
                size="small"
                color="primary"
                // disabled={userType === "admin" && true}
                disabled={row.checkOut < moment().format('YYYY-MM-DD HH:mm:ss')}
                onClick={() => handleCheckoutDrawerOpen(row)}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </CustomTooltip>
            {/* View OTP Icon */}
            <CustomTooltip title="View OTP">
              <IconButton
                size="small"
                color="primary"
                onClick={() => handleViewOTP(row)}
              >
                <Icon icon="mdi:email-outline" width={20} />
                {/* <MailOutlineIcon width={24} /> */}
              </IconButton>
            </CustomTooltip>
            {/* Resend OTP Icon */}
            <CustomTooltip title="Resend OTP">
              <IconButton
                size="small"
                color="primary"
                // disabled={userType === "admin" && true}
                onClick={() => handleResendOTP(row)}
              >
                <Icon icon="mdi:email-resend-outline" width={20} />
              </IconButton>
            </CustomTooltip>
            {/* Whatsapp Icon */}
            <CustomTooltip title="Send OTP">
              <IconButton
                size="small"
                color="primary"
                // disabled={userType === "admin" && true}
                onClick={() => handleWhatsappSend(row)}
              >
                <WhatsAppIcon fontSize="small" />
              </IconButton>
            </CustomTooltip>
          </Stack>
        );
      },
    },
  ];

  // handle upload document file
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileKey, setFileKey] = useState(Date.now());
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 3) {
      toast.error("File should not be more than 3");
      return;
    }
    if (
      files.some(
        (file) =>
          !["image/png", "image/jpeg", "image/jpg", "image/gif"].includes(
            file.type
          )
      )
    ) {
      toast.error(
        "Invalid file type. Only PNG, JPEG, JPG and GIF files are allowed."
      );
      return;
    }

    setSelectedFiles(files);
  };

  const removeFile = (fileToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  };

  const viewFile = (file) => {
    window.open(URL.createObjectURL(file), "_blank");
  };

  // drawer functionality start--------------------------------------------
  const [checkoutDrawerOpen, setCheckoutDrawerOpen] = useState(false);

  const handleCheckoutDrawerOpen = (checkoutValue) => {
    setCheckoutDrawerOpen(true);
    // setConfirmationOpen(true);
    if (checkoutValue.customer && checkoutValue.room) {
      setActionMode("edit");
      setEditCustomer(checkoutValue);
      // formik.setValues({
      //     checkOutDate: moment(checkoutValue.checkOut),
      // })
      // console.log("checkoutValue:", checkoutValue);
    } else {
      setActionMode("add");
      formik.resetForm();
    }
  };

  const handleCheckoutDrawerClose = () => {
    setCheckoutDrawerOpen(false);
    setSelectedFiles([]);
    setCustomerDocument(null);
  };

  // update checkout date
  const handleCheckoutDateChange = (newDate) => {
    setEditCustomer({
      ...editCustomer,
      checkOut: newDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    });
  };

  // handle confirmation before form submit
  const handleOpenConfirmation = () => {
    setConfirmationOpen(true);
  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  const convertToFormData = (data, actionType) => {
    const formData = new FormData();

    if (actionType === "newCustomer") {
      // console.log("Processing new customer data:", data)
      // Append basic properties
      formData.append('firstName', data.firstName);
      formData.append('lastName', data.lastName);
      formData.append('contactNumber', data.contactNumber);
      formData.append('ageCategory', data.ageCategory);
      formData.append('gender', data.gender);
      formData.append('address', data.address);
      formData.append('purposeOfVisit', data.purposeOfVisit);
      formData.append('arrivalFrom', data.arrivalFrom);
      if (data.photoIdProof) {
        formData.append("documentType", data.photoIdProof);
      }
      formData.append("isActive", data.isActive);

      // Append files if they exist
      if (data.files && data.files.length > 0) {
        data.files.forEach((file, index) => {
          formData.append('files', file);
        });
      }

      // Append roomIds as a JSON string
      // if (data.roomIds && data.roomIds.length > 0) {
      //   formData.append('roomIds', JSON.stringify(data.roomIds));
      //   console.log("first", data.roomIds);
      // }

      if (data.roomIds && data.roomIds.length > 0) {
        const roomsArray = data.roomIds.map((room) => {
          const roomData = {
            roomId: room.roomId,
            checkIn: room.checkIn,
            checkOut: room.checkOut,
            customers: room.customers.map((guestId) => guestId),
          };

          return roomData;
        });

        // Now append the entire array as a JSON string
        formData.append('roomIds', JSON.stringify(roomsArray));
      }
    } else if (actionType === "existingCustomer") {
      // console.log("Processing existing customer data:", data);
      // Append basic properties
      formData.append("customerId", existingCustomer?.id);
      formData.append('address', data.address);
      formData.append('purposeOfVisit', data.purposeOfVisit);
      formData.append('arrivalFrom', data.arrivalFrom);

      // Append files if they exist
      if (data.files && data.files.length > 0) {
        data.files.forEach((file, index) => {
          formData.append("files", file); // Use 'files' as the key for multiple files
        });
        formData.append("documentType", data.photoIdProof);
      }

      // // Append roomIds as a JSON string
      // if (data.roomIds && data.roomIds.length > 0) {
      //   formData.append('roomIds', JSON.stringify(data.roomIds));
      // }

      // Append roomIds as a JSON string
      if (data.roomIds && data.roomIds.length > 0) {
        const roomsArray = data.roomIds.map((room) => {
          const roomData = {
            roomId: room.roomId,
            checkIn: room.checkIn,
            checkOut: room.checkOut,
            customers: room.customers.map((guestId) => guestId),
          };
          return roomData;
        });
        formData.append('roomIds', JSON.stringify(roomsArray));
      }
    }
    return formData;
  };

  const handleSubmitForm = async (values) => {
    // moment(editCustomer.checkOut, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
    // console.log("form values", values);
    const checkoutDetailsWithNewCustomer = {
      firstName: values.customerFirstName,
      lastName: values.customerLastName,
      contactNumber: values.customerContactNumber,
      ageCategory: values.customerAgeGroup,
      gender: values.customerGender,
      address: values.customerAddress,
      purposeOfVisit: values.customerPurposeOfVisit,
      arrivalFrom: values.customerArrivalFrom,
      photoIdProof: values.customerDocumentType,
      isActive: values.customerIsActive,
      files: selectedFiles,
      roomIds: values?.rooms?.map((room) => ({
        roomId: +room.roomId,
        checkIn: room.checkInDate
          ? moment(room.checkInDate, "YYYY-MM-DD HH:mm:ss")
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]")
          : null,
        checkOut: room.checkOutDate
          ? moment(room.checkOutDate, "YYYY-MM-DD HH:mm:ss")
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]")
          : null,
        customers: room?.guests?.map(guest => guest.id),
      })),
    };

    const checkoutWithExistingCustomer = {
      customerId: existingCustomer?.id,
      address: values.customerAddress,
      purposeOfVisit: values.customerPurposeOfVisit,
      arrivalFrom: values.customerArrivalFrom,
      roomIds: values?.rooms?.map((room) => ({
        roomId: room.roomId,
        checkIn: room.checkInDate
          ? moment(room.checkInDate, "YYYY-MM-DD HH:mm:ss")
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]")
          : null,
        checkOut: room.checkOutDate
          ? moment(room.checkOutDate, "YYYY-MM-DD HH:mm:ss")
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]")
          : null,
        customers: room?.guests?.map(guest => guest.id),
      })),
      photoIdProof: values.customerDocumentType,
      files: selectedFiles,
    };

    let checkoutDetails;
    if (existingCustomer) {
      checkoutDetails = convertToFormData(checkoutWithExistingCustomer, "existingCustomer");
      // console.log("existingCustomer", checkoutWithExistingCustomer);
    } else {
      checkoutDetails = convertToFormData(checkoutDetailsWithNewCustomer, "newCustomer");
      // console.log("newCustomer", checkoutDetailsWithNewCustomer);
    }

    try {
      // Check if documentType and files are properly provided
      const hasDocumentType = values.customerDocumentType;
      const hasFiles = selectedFiles.length > 0;
      if (actionMode === "add") {
        // Print FormData entries
        // console.log('checkin FormData contents:');
        // checkoutDetails.forEach((value, key) => {
        //   if (value instanceof File) {
        //     console.log(`${key}: [File] ${value.name} (${value.size} bytes)`);
        //   } else {
        //     console.log(`${key}: ${value}`);
        //   }
        // });

        if ((hasDocumentType && hasFiles) || (!hasDocumentType && !hasFiles)) {
          const response = await createCheckout(checkoutDetails);
          if (response) {
            setCustomerCheckout([...customerCheckout, ...response]);
            toast.success("Checkout created successfully");
            handleCloseConfirmation();
            handleCheckoutDrawerClose();
            formik.resetForm();
            getCheckouts();
          }
        } else {
          toast.error("Please add both Document type and upload document");
          setConfirmationOpen(false);
        }
      } else if (actionMode === "edit") {
        editCheckout();
        formik.resetForm();
      }
    } catch (error) {
      if (error.response?.data?.statusCode === 400) {
        toast.error(error.response?.data?.message?.message[0]);
        handleCloseConfirmation();
      } else if (error.response?.data?.statusCode === 409) {
        toast.error(error.response?.data?.message);
        handleCloseConfirmation();
      } else {
        toast.error(error.response?.data?.message);
      }
    }
  };

  const handleConfirmAction = async (confirmed) => {
    if (confirmed) {
      await handleSubmitForm(formik.values);
    } else {
      handleCloseConfirmation();
    }
  };

  // handle form
  // create checkout
  const formik = useFormik({
    initialValues: {
      customerContactNumber: "",
      customerFirstName: "",
      customerLastName: "",
      customerAgeGroup: "",
      customerGender: "",
      customerAddress: "",
      customerPurposeOfVisit: "",
      customerArrivalFrom: "",
      customerDocumentType: "",
      customerIsActive: true,
      selectedCustomer: null,
      rooms: [{ roomId: '', checkInDate: null, checkOutDate: null, guests: [] }],
    },
    validationSchema:
      actionMode === "add" ? customerCheckoutValidationSchema : null,
    onSubmit: () => {
      handleOpenConfirmation();
    },
  });

  // drawer functionality end--------------------------------------------------

  // handle otp modal
  const [viewOtpModal, setViewOtpModal] = useState(false);
  const [customerOtp, setCustomerOtp] = useState(null);

  const handleViewOTP = (data) => {
    setViewOtpModal(true);
    setCustomerOtp(data.room.otp);
  };

  const handleCloseOTPmodal = () => {
    setViewOtpModal(false);
  };

  const handleResendOTP = async (data) => {
    const response = await resendOtp(data.room.uniqueCode);
    if (response) {
      toast.success("OTP has been resent successfully");
    }
  };

  const handleWhatsappSend = (data) => {
    const url = `https://api.whatsapp.com/send/?phone=${data?.customer?.contactNumber}&text=Your OTP is ${data?.room?.otp} to access the guestcare services`;
    window.open(url, "_blank");
  };
  // handle otp modal ---------------------------------------------------------

  // handle View Guest modal
  const [viewGuestModal, setViewGuestModal] = useState({
    isOpen: false,
    data: null
  });
  const openGuestModal = (guestData) => {
    setViewGuestModal({
      isOpen: true,
      data: guestData,
    });
  };

  const closeGuestModal = () => {
    setViewGuestModal({
      isOpen: false,
      data: null,
    });
  };
  // handle View Guest modal end ---------------------------------------------

  // handle checkout now
  // update checkout with current time
  const [checkoutNowDialogOpen, setCheckoutNowDialogOpen] = useState(false);
  const handleCheckoutNow = (row) => {
    setEditCustomer(row);
    setCheckoutNowDialogOpen(true);
  };

  const handleCheckoutNowDialogClose = () => {
    setCheckoutNowDialogOpen(false);
  };

  const handleCheckoutNowConfirmation = async () => {
    setCheckoutNowDialogOpen(false);
    await editCheckout("now");
  };

  return (
    <>
      <Container
        sx={{
          paddingX: "8px !important",
          marginX: "auto !important",
          maxWidth: "100% !important",
        }}
      >
        <PageHeader
          headerName="Check-in"
          handleModalOpen={handleCheckoutDrawerOpen}
        />

        <CheckoutDataFilter
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          openEndDatePicker={openEndDatePicker}
          setOpenEndDatePicker={setOpenEndDatePicker}
          getCheckouts={getCheckouts}
          handleExport={handleExport}
          activeGlobalBranch={activeGlobalBranch}
          setCustomerCheckout={setCustomerCheckout}
        />
        {loading ? (
          <Loader />
        ) : (
          <>
            <Card sx={{ mt: 3, overflowX: "auto" }}>
              <DataGrid
                rows={customerCheckout}
                columns={customerCheckoutColumns}
                localeText={{ noRowsLabel: "No Check-in found" }}
                autoHeight={true}
                hideFooterPagination
                disableRowSelectionOnClick
                onSortModelChange={(sortModel) => {
                  if (sortModel.length > 0) {
                    const { field, sort } = sortModel[0];
                    // const newOrder = sort === "asc" ? "DESC" : "ASC";
                    if (field === "checkIn") {
                      setColumn("checkIn");
                      setDirection(sort.toUpperCase());
                    } else if (field === "checkOut") {
                      setColumn("checkOut");
                      setDirection(sort.toUpperCase());
                    }
                  } else {
                    // setSortField("checkIn");
                    // setSortOrder("DESC");
                  }
                }}
                sx={{
                  "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus":
                  {
                    outline: "none",
                  },
                  "& .MuiDataGrid-row": {
                    position: "relative",
                  },
                  // header-bottom
                  "& .MuiDataGrid-row:first-child::before": {
                    content: "''",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "0.5px",
                    backgroundColor: "#0000004D",
                  },
                  // each-row-bottom
                  "& .MuiDataGrid-row::after": {
                    content: "''",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "0.5px",
                    backgroundColor: "#0000004D",
                  },
                  // footer-bottom
                  "& .MuiDataGrid-row:last-child::after": {
                    content: "''",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "0.5px",
                    backgroundColor: "#0000004D",
                  },
                  minWidth: "678px",
                  width: "100%",
                  overflowX: "auto",
                }}
              />
            </Card>
          </>
        )}
      </Container>

      {/* Server Pagination */}
      <ServerPagination
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        prevPage={prevPage}
        nextPage={nextPage}
        data={customerCheckout}
        totalSize={totalSize}
      />

      <CheckoutDrawer
        checkoutDrawerOpen={checkoutDrawerOpen}
        handleCheckoutDrawerClose={handleCheckoutDrawerClose}
        formik={formik}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        fileKey={fileKey}
        setFileKey={setFileKey}
        handleFileChange={handleFileChange}
        removeFile={removeFile}
        viewFile={viewFile}
        getExistingCustomer={getExistingCustomer}
        editCustomer={editCustomer}
        actionMode={actionMode}
        handleCheckoutDateChange={handleCheckoutDateChange}
        customerDocument={customerDocument}
        activeGlobalBranch={activeGlobalBranch}
      />
      <ConfirmationDialog
        open={confirmationOpen}
        onClose={handleConfirmAction}
        actionMode={actionMode}
      />
      <OtpModal
        handleViewOTP={viewOtpModal}
        customerOtp={customerOtp}
        handleCloseOTPmodal={handleCloseOTPmodal}
      />
      <GuestInfoModal
        isOpen={viewGuestModal.isOpen}
        onClose={closeGuestModal}
        guestData={viewGuestModal.data}
      />
      <CheckoutNowConfirmationDialog
        open={checkoutNowDialogOpen}
        onClose={handleCheckoutNowDialogClose}
        onConfirm={handleCheckoutNowConfirmation}
      />
    </>
  );
};

export default CustomerCheckout;
