import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Autocomplete
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Iconify from "../../components/iconify/Iconify";
import ClearIcon from "@mui/icons-material/Clear";
import { Toaster, toast } from "react-hot-toast";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import deleteIcon from "../../assets/icons/trash.png";
import EditIcon from "../../assets/icons/EditIcon";

import * as Yup from "yup";
import { useFormik } from "formik";

// apis
import {
  addRoomType,
  deleteRoomType,
  getRoomTypeByBranch,
  updateRoomType,
} from "../../api/roomType";
import palette from "../../theme/palette";
import SearchInput from "../../components/SearchInput";
import { useClient } from "../../context/ClientProvider";
import { useIsSmallScreen } from "../../hooks/useResponsive";

const roomTypeValidationSchema = Yup.object().shape({
  roomTypeName: Yup.string().required("Name is required"),
  roomTypeDescription: Yup.string().optional("RoomType description"),
  // roomTypeIsActive: Yup.boolean().required("Active is required"),
  roomTypePrice: Yup.string().required("Price is required"),
  adultsPerRoom: Yup.string().required("Number of adults is required"),
  kidsPerRoom: Yup.string().required("Number of kids is required")
});

const RoomType = () => {
  const isSmallScreen = useIsSmallScreen();
  const [roomTypes, setRoomTypes] = useState([]);
  const [searchRoomTypeValue, setSearchRoomTypeValue] = useState("");
  const [editedRoomType, setEditedRoomType] = useState(null);
  const { activeGlobalBranch } = useClient();

  const AdultsNumber = Array.from({ length: 10 }, (_, i) => i + 1);
  const KidsNumber = Array.from({ length: 11 }, (v, i) => i )

  // delete modal
  const [openDeleteDialog, setOpenDeleteDialog] = useState({
    status: false,
    deleteId: null,
  });

  // modal mode
  const [modalMode, setModalMode] = useState("add");

  // modal
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = (roomTypeData) => {
    if (activeGlobalBranch) {
      setModalOpen(true);
      if (roomTypeData.name) {
        setModalMode("edit");
        setEditedRoomType(roomTypeData);
        formik.setValues({
          roomTypeName: roomTypeData.name,
          roomTypeDescription: roomTypeData.description,
          // roomTypeIsActive: roomTypeData.isActive,
          adultsPerRoom: roomTypeData.noOfAdult,
          kidsPerRoom: roomTypeData.noOfKid,
          roomTypePrice: roomTypeData.price,
        });
      } else {
        setModalMode("add");
        formik.resetForm();
      }
    } else {
      toast.error("Select Client and Branch");
    }
  };

  // get roomType by branchId
  const roomTypeData = async () => {
    try {
      const response = await getRoomTypeByBranch(activeGlobalBranch?.id);
      setRoomTypes(response);
    } catch (error) {
      console.log("get branch by client error", error);
    }
  };

  // editRoomTypeData
  const editRoomTypeData = async (roomTypeDetails) => {
    if (
      roomTypes.find((roomType) => roomType.name === roomTypeDetails.name) &&
      editedRoomType.name !== roomTypeDetails.name
    ) {
      toast.error("RoomType name already exists");
    } else {
      try {
        const response = await updateRoomType(editedRoomType.id, roomTypeDetails);
        if (response) {
          setRoomTypes((prevRoomTypes) =>
            prevRoomTypes.map((roomType) =>
              roomType.id === editedRoomType.id
                ? {
                  ...roomTypes,
                  id: editedRoomType.id,
                  name: roomTypeDetails.name,
                  description: roomTypeDetails.description,
                  // isActive: roomTypeDetails.isActive,
                  noOfAdult: roomTypeDetails.noOfAdult,
                  noOfKid: roomTypeDetails.noOfKid,
                  price: roomTypeDetails.price,
                  uniqueCode: response.uniqueCode
                }
                : roomType
            )
          );
          toast.success("RoomType updated successfully");
          handleModalClose();
        }
      } catch (error) {
        if (error.response?.data?.statusCode === 400) {
          toast.error(error.response?.data?.message?.message[0]);
        } else if (error.response?.data?.statusCode === 409) {
          toast.error(error.response?.data?.message);
        } else {
          toast.error(error.response?.data?.message);
        }
      }
    }
  };

  // deleteRoomTypeData
  const deleteRoomTypeData = async () => {
    const { deleteId } = openDeleteDialog;
    try {
      const updatedRoomTypeValue = roomTypes.filter((roomType) => roomType.id !== deleteId);
      const response = await deleteRoomType(deleteId);
      if (response) {
        toast.success("RoomType deleted successfully");
        setRoomTypes(updatedRoomTypeValue);
      }
    } catch (error) {
      toast.error("Error on deleting roomType")
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 2,
    },
    /* {
      field: "isActive",
      headerName: "isActive",
      // width: 200,
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    }, */
    {
      field: "noOfAdult",
      headerName: "Adults per room",
      flex: 1.5,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "noOfKid",
      headerName: "Kids per room",
      flex: 1.5,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "uniqueCode",
      headerName: "Unique Code",
      flex: 1.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.uniqueCode}</>,
    },
    {
      field: "actions",
      headerName: "Actions",
      with: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const handleDeleteDialog = (id) => {
          setOpenDeleteDialog({
            ...openDeleteDialog,
            status: true,
            deleteId: id,
          });
        };
        const handleDeleteClose = () => {
          setOpenDeleteDialog({ ...openDeleteDialog, status: false });
        };
        const handleConfirmDelete = () => {
          deleteRoomTypeData();
          handleDeleteClose();
        };
        return (
          <Stack direction="row" spacing={0}>
            {/* Edit Icon */}
            <Tooltip title="Edit">
              <IconButton
                size="medium"
                color="primary"
                onClick={() => handleModalOpen(row)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            {/* Delete Icon */}
            <Tooltip title="Delete">
              <IconButton
                size="medium"
                color="error"
                onClick={() => handleDeleteDialog(row.id)}
              >
                <img src={deleteIcon} />
              </IconButton>
            </Tooltip>
            {/* Confirmation Modal */}
            <ConfirmationModal
              open={openDeleteDialog.status}
              onClose={handleDeleteClose}
              onConfirm={handleConfirmDelete}
              iconName="roomType"
            />
          </Stack>
        );
      },
    },
  ];

  // create roomType
  const formik = useFormik({
    initialValues: {
      roomTypeName: "",
      roomTypeDescription: "",
      // roomTypeIsActive: true,
      adultsPerRoom: "",
      kidsPerRoom: "",
      roomTypePrice: ""
    },
    validationSchema: roomTypeValidationSchema,
    onSubmit: async (values) => {
      const roomTypeDetails = {
        name: values.roomTypeName,
        description: values.roomTypeDescription,
        // isActive: values.roomTypeIsActive,
        noOfAdult: values.adultsPerRoom,
        noOfKid: values.kidsPerRoom,
        price: values.roomTypePrice,
        branchId: activeGlobalBranch.id,
      };
      try {
        if (modalMode === "add") {
          if (roomTypes.find((roomType) => roomType.name === roomTypeDetails.name)) {
            toast.error("RoomType name already exists");
          } else {
            const response = await addRoomType(roomTypeDetails);
            response && toast.success("RoomType added successfully");
            // roomTypeData();
            setRoomTypes((prevRoomTypes) => [...prevRoomTypes, response]);
            handleModalClose();
          }
        } else if (modalMode === "edit") {
          editRoomTypeData(roomTypeDetails);
        }
      } catch (error) {
        if (error.response?.data?.statusCode === 400) {
          toast.error(error.response?.data?.message?.message[0]);
        } else if (error.response?.data?.statusCode === 409) {
          toast.error(error.response?.data?.message);
        } else {
          toast.error(error.response?.data?.message);
        }
        // console.error("create roomType Error:", error);
      }
    },
  });

  useEffect(() => {
    activeGlobalBranch && roomTypeData();
  }, [activeGlobalBranch]);

  return (
    <>
      <Container
        sx={{
          paddingX: "8px !important",
          marginX: "auto !important",
          maxWidth: "100% !important",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
          sx={{ border: "", ...(isSmallScreen && { marginTop: 2 }) }}
        >
          <Typography variant="h5" gutterBottom color={palette.primary.light}>
            RoomType
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleModalOpen}
          >
            New RoomType
          </Button>
        </Stack>
        <Card sx={{ mt: 3, overflowX: "auto" }}>
          {/* search */}
          <SearchInput
            sx={{ ml: 1, mt: 1 }}
            label="roomType"
            value={searchRoomTypeValue}
            onChange={(e) => {
              setSearchRoomTypeValue(e.target.value);
            }}
          />
          <DataGrid
            rows={roomTypes?.filter((roomType) =>
              Object.values(roomType).some(
                (value) =>
                  value !== null &&
                  value
                    ?.toString()
                    .toLowerCase()
                    .includes(searchRoomTypeValue.toLowerCase())
              )
            )}
            columns={columns}
            rowHeight={55}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10, 50]}
            disableRowSelectionOnClick
            autoHeight
            sx={{
              "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-row": {
                position: "relative",
              },
              // header-bottom
              "& .MuiDataGrid-row:first-child::before": {
                content: "''",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // each-row-bottom
              "& .MuiDataGrid-row::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // footer-bottom
              "& .MuiDataGrid-row:last-child::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              minWidth: "678px",
              width: "100%",
              overflowX: "auto",
            }}
            localeText={{ noRowsLabel: "No roomType found" }}
          />
        </Card>

        <Drawer anchor="right" open={modalOpen} onClose={handleModalClose}>
          <Container maxWidth="sm">
            <Box
              sx={{
                bgcolor: "background.paper",
                width: 400,
                marginY: 2,
                ...(isSmallScreen && { width: 280 })
              }}
            >
              <IconButton
                sx={{ position: "absolute", top: 5, right: 5 }}
                color={palette.grey[500]}
                onClick={handleModalClose}
              >
                <ClearIcon />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                <Typography variant="h6" gutterBottom mb={3}>
                  {modalMode === "add"
                    ? `Add RoomType on ${activeGlobalBranch?.name}`
                    : `Edit RoomType on ${activeGlobalBranch?.name}`}
                </Typography>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Name *"
                      name="name"
                      type="text"
                      {...formik.getFieldProps("roomTypeName")}
                    />
                    {formik.touched.roomTypeName && formik.errors.roomTypeName ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.roomTypeName}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      multiline
                      rows={2}
                      {...formik.getFieldProps("roomTypeDescription")}
                    />
                    {formik.touched.roomTypeDescription &&
                      formik.errors.roomTypeDescription ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.roomTypeDescription}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={AdultsNumber}
                      disableClearable
                      getOptionLabel={(option) => option.toString() || ""}
                      value={AdultsNumber.includes(formik.values.adultsPerRoom) ? formik.values.adultsPerRoom : null}
                      onChange={(event, value) => formik.setFieldValue("adultsPerRoom", value)}
                      renderInput={(params) => (
                        <TextField {...params} label="Adults per room *" />
                      )}
                      isOptionEqualToValue={(option, value) => option === value}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={KidsNumber}
                      disableClearable
                      getOptionLabel={(option) => option.toString() || ""}
                      value={KidsNumber.includes(formik.values.kidsPerRoom) ? formik.values.kidsPerRoom : null}
                      onChange={(event, value) => formik.setFieldValue("kidsPerRoom", value)}
                      renderInput={(params) => (
                        <TextField {...params} label="Kids per room *" />
                      )}
                      isOptionEqualToValue={(option, value) => option === value}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Price *"
                      name="price"
                      type="number"
                      {...formik.getFieldProps("roomTypePrice")}
                    />
                    {formik.touched.roomTypePrice &&
                      formik.errors.roomTypePrice ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.roomTypePrice}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
                <Box
                  mt={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  // disabled={!formik.dirty}
                  >
                    {modalMode === "add" ? "Add" : "Update"}
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    sx={{
                      backgroundColor: palette.grey[500],
                      "&:hover": {
                        backgroundColor: palette.grey[600],
                      },
                      marginLeft: 1,
                    }}
                    onClick={() => formik.resetForm()}
                  >
                    clear
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Drawer>
      </Container>

      <Toaster position="top-right" />
    </>
  );
};

export default RoomType;