import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Drawer,
  Stack,
  TextField,
  Tooltip,
  tooltipClasses,
  styled,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Iconify from "../../components/iconify/Iconify";
import { toast } from "react-hot-toast";
import { DataGrid } from "@mui/x-data-grid";
import palette from "../../theme/palette";
import ClearIcon from "@mui/icons-material/Clear";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import SearchInput from "../../components/SearchInput";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useClient } from "../../context/ClientProvider";
import deleteIcon from "../../assets/icons/trash.png";
import ServerPagination from "../../components/ServerPagination";
import EditIcon from "../../assets/icons/EditIcon";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import {
  addCustomer,
  updateCustomer,
  getCustomerByBranch,
  deleteCustomer,
  findAllCustomersByBranch,
} from "../../api/customer";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useIsSmallScreen } from "../../hooks/useResponsive";
import ShowDocumentPasswordModal from "../../components/Modal/ShowDocumentPasswordModal";
import { useAuth } from "../../context/AuthProvider";
import { viewsuperadmindocumentpassword } from "../../api/superadmin";
import { viewadmindocumentpassword } from "../../api/user";

const customerValidationSchema = Yup.object().shape({
  customerFirstName: Yup.string().required("Customer First name is required"),
  customerLastName: Yup.string().required("Customer Last name is required"),
  customerContactNumber: Yup.string()
    .required("Customer Contact Number is required")
    .matches(/^[0-9]+$/, "Must be a valid number")
    .min(10, "Must be at least 10 digits")
    .max(10, "Must not exceed 10 digits"),
  customerGender: Yup.string().required("Customer Gender is required"),
  customerAgeCategory: Yup.string().required("Customer Age category is required"),
  // customerDocumentType: Yup.optional(),
  // customerIdNumber: Yup.string()
  //   .optional()
  //   .matches(/^[a-zA-Z0-9]+$/, "Must be a valid id number")
  //   .min(5, "Must be at least 5 digits"),
  customerIsActive: Yup.boolean().required(
    "Customer active status is required"
  ),
});

export const Customer = () => {
  const isSmallScreen = useIsSmallScreen();
  const userType = JSON.parse(localStorage.getItem('userType'));
  const [customer, setCustomer] = useState([]);
  const [searchCustomerValue, setSearchCustomerValue] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState({
    status: false,
    deleteId: null,
  });

  const { activeGlobalBranch } = useClient();
  const { globalUser } = useAuth();
  const [editedCustomer, setEditedCustomer] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedFiles([]);
  };

  // duplicate Modal
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [currentNumber, setCurrentNumber] = useState("");
  const [isDuplicateCustomer, setIsDuplicateCustomer] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalSize, setTotalSize] = useState(1);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    customerData();
  }, [currentPage, pageSize, activeGlobalBranch]);

  // get Customer data by branchId
  const customerData = async () => {
    if (activeGlobalBranch) {
      try {
        const response = await findAllCustomersByBranch({
          currentPage: currentPage,
          pageSize: pageSize,
          branchId: activeGlobalBranch.id,
        });
        if (response) {
          const { data, totalPage, totalSize } = response;
          setCustomer(data);
          setTotalPages(totalPage);
          setTotalSize(totalSize);
        }
      } catch (error) {
        console.log("Error in fetching customer data for branch id ", error);
      }
    }
  };

  const handleModalOpen = (customerData) => {
    setModalOpen(true);
    if (customerData.firstName) {
      setModalMode("edit");
      setEditedCustomer(customerData);
      formik.setValues({
        customerFirstName: customerData.firstName,
        customerLastName: customerData.lastName,
        customerContactNumber: customerData.contactNumber,
        customerGender: customerData.gender,
        customerAgeCategory: customerData.ageCategory,
        customerDocumentType: customerData.documentType,
        customerIsActive: customerData.isActive,
      });
    } else {
      setModalMode("add");
      formik.resetForm();
    }
  };

  const checkIfNumberExists = (customers, contactNumber) => {
    return customers.some(
      (customer) => customer.contactNumber === contactNumber
    );
  };

  const handleContactNumberChange = (contactNumber) => {
    const duplicateExists = checkIfNumberExists(customer, contactNumber);
    if (duplicateExists) {
      setIsDuplicateCustomer(duplicateExists);
      setIsDuplicateModalOpen(true);
    }
  };

  const handleConfirmDuplicate = () => {
    // Proceed with adding the customer
    // addCustomer(formValues);
    setIsDuplicateModalOpen(false);
  };

  const handleCloseDuplicateModal = () => {
    setIsDuplicateModalOpen(false);
    // Clear the form field
    // setFormValues(prevValues => ({
    //   ...prevValues,
    //   customerContactNumber: ""
    // }));
    formik.setFieldValue("customerContactNumber", "");
  };

  // editCustomerData
  const editCustomerData = async (customerDetails) => {
    // Print FormData entries
    // console.log('FormData edit contents:-------------');
    // customerDetails.forEach((value, key) => {
    //   if (value instanceof File) {
    //     console.log(`${key}: [File] ${value.name} (${value.size} bytes)`);
    //   } else {
    //     console.log(`${key}: ${value}`);
    //   }
    // });

    try {
      const response = await updateCustomer(editedCustomer.id, customerDetails);
      // Update the local state
      if (response) {
        setCustomer((prevCustomer) =>
          prevCustomer.map((customer) =>
            customer.id === editedCustomer.id
              ? {
                ...customer,
                ...response.data,
                id: editedCustomer.id,
              }
              : customer
          )
        );
        toast.success("Customer updated successfully");
        handleModalClose();
        customerData();
      }
    } catch (error) {
      if (error.response?.data?.statusCode === 400) {
        toast.error(error.response?.data?.message?.message[0]);
      } else if (error.response?.data?.statusCode === 409) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error(error.response?.data?.message);
      }
    }
  };

  // delete Customer data
  const deleteCustomerData = async () => {
    const { deleteId } = openDeleteDialog;
    try {
      const serviceAfterDelete = customer.filter(
        (cust) => cust.id !== deleteId
      );
      const response = await deleteCustomer(deleteId);
      if (response) {
        setCustomer(serviceAfterDelete);
        toast.success("Customer deleted successfully");
      }
    } catch (error) {
      toast.error("Error in deleting Customer");
    }
  };

  const formik = useFormik({
    initialValues: {
      customerFirstName: "",
      customerLastName: "",
      customerDocumentType: null,
      // customerIdNumber: "",
      customerContactNumber: "",
      customerGender: null,
      customerAgeCategory: null,
      customerIsActive: true,
    },
    validationSchema: customerValidationSchema,
    onSubmit: async (values) => {
      // Create a new FormData instance
      const formData = new FormData();

      // Append regular form data
      formData.append('firstName', values.customerFirstName);
      formData.append('lastName', values.customerLastName);
      formData.append('contactNumber', values.customerContactNumber);

      formData.append('isActive', values.customerIsActive);

      if (values.customerDocumentType) {
        formData.append('documentType', values.customerDocumentType);
      }

      if (activeGlobalBranch && modalMode === "add") {
        formData.append('branchId', activeGlobalBranch.id);
      }

      // Append files if they exist
      if (selectedFiles.length > 0) {
        selectedFiles.forEach((file, index) => {
          formData.append('files', file);
        });
      }

      // Print FormData entries
      // console.log('FormData contents:');
      // formData.forEach((value, key) => {
      //   if (value instanceof File) {
      //     console.log(`${key}: [File] ${value.name} (${value.size} bytes)`);
      //   } else {
      //     console.log(`${key}: ${value}`);
      //   }
      // });

      try {
        // Check if documentType and files are properly provided
        const hasDocumentType = values.customerDocumentType;
        const hasFiles = selectedFiles.length > 0;

        if (modalMode === "add") {
          if ((hasDocumentType && hasFiles) || (!hasDocumentType && !hasFiles)) {
            const response = await addCustomer(formData);
            response && toast.success("New Customer added successfully");
            formik.resetForm();
            handleModalClose();
            customerData();
          } else {
            toast.error("Please add both Document type and upload document");
          }
        } else {
          editCustomerData(formData);
        }
      } catch (error) {
        if (error.response?.data?.statusCode === 400) {
          toast.error(error.response?.data?.message?.message[0]);
        } else if (error.response?.data?.statusCode === 409) {
          toast.error(error.response?.data?.message);
        } else {
          toast.error(error.response?.data?.message);
        }
      }
    },
  });

  // handle document file
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileKey, setFileKey] = useState(Date.now());
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 3) {
      toast.error("File should not be more than 3")
      return;
    }
    if (files.some(file => !['image/png', 'image/jpeg', 'image/jpg', 'image/gif'].includes(file.type))) {
      toast.error("Invalid file type. Only PNG, JPEG, JPG and GIF files are allowed.");
      return;
    }

    setSelectedFiles(files);
  };

  const removeFile = (fileToRemove) => {
    setSelectedFiles(prevFiles => prevFiles.filter(file => file !== fileToRemove));
  };

  const viewFile = (file) => {
    window.open(URL.createObjectURL(file), "_blank");
  };

  // handle view document confirmation modal
  const [viewDocumentConfirmationModal, setViewDocumentConfirmationModal] = useState(false);
  const [documentData, setDocumentData] = useState(null);

  const handleViewDocumentConfirmationModal = (data) => {
    setDocumentData(data);
    setViewDocumentConfirmationModal(true);
    // handleDocumentViewOTP(data);
  };

  const handleCloseDocumentConfirmationModal = () => {
    setDocumentData(null);
    setViewDocumentConfirmationModal(false);
  };

  // handle show document otp modal
  const [viewDocumentOtpModal, setViewDocumentOtpModal] = useState(false);

  const handleDocumentViewOTP = async () => {
    // send otp to login user
    if (globalUser.user.userType === "super_admin") {
      const otpResponse = await viewsuperadmindocumentpassword({
        superAdminId: globalUser.user?.userId
      });
      if (otpResponse) {
        setViewDocumentConfirmationModal(false);
        setViewDocumentOtpModal(true);
      } else {
        toast.error("Facing error while sending otp");
      }
    } else if (globalUser.user.userType === "admin") {
      const otpResponse = await viewadmindocumentpassword({
        userId: globalUser.user?.userId
      });
      if (otpResponse) {
        setViewDocumentConfirmationModal(false);
        setViewDocumentOtpModal(true);
      } else {
        toast.error("Facing error while sending otp");
      }
    } else {
      toast.error("Invalid User");
    }

  }

  const handleCloseDocumentOTPmodal = () => {
    setViewDocumentOtpModal(false);
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#A94C9B40",
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <LightTooltip title={row.firstName}>
          <span>{row.firstName}</span>
        </LightTooltip>
      ),
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <LightTooltip title={row.lastName}>
          <span>{row.lastName}</span>
        </LightTooltip>
      ),
    },
    {
      field: "contactNumber",
      headerName: "Contact Number",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <LightTooltip title={row.gender}>
          <span>{row.gender ?? "NA"}</span>
        </LightTooltip>
      ),
    },
    {
      field: "ageCategory",
      headerName: "Age Category",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <LightTooltip title={row.ageCategory}>
          <span>{row.ageCategory ?? "NA"}</span>
        </LightTooltip>
      ),
    },
    {
      field: "documentType",
      headerName: "Document Type",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <LightTooltip title={row?.documentType}>
          <span>{row?.documentType ? row?.documentType : "NA"}</span>
        </LightTooltip>
      ),
    },
    // {
    //   field: "idNumber",
    //   headerName: "Id Number",
    //   flex: 1,
    //   sortable: false,
    //   filterable: false,
    //   disableColumnMenu: true,
    // },
    {
      field: "isActive",
      headerName: "IsActive",
      flex: 0.6,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      // with: 50,
      flex: (userType === "super_admin" || userType === "admin") ? 1.6 : 0.6,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const handleDeleteDialog = (id) => {
          setOpenDeleteDialog({
            ...openDeleteDialog,
            status: true,
            deleteId: id,
          });
        };
        const handleDeleteClose = () => {
          setOpenDeleteDialog({ ...openDeleteDialog, status: false });
        };
        const handleConfirmDelete = () => {
          deleteCustomerData();
          handleDeleteClose();
        };

        return (
          <Stack direction="row" spacing={0}>
            {/* Edit Icon */}
            <Tooltip title="Edit">
              <IconButton
                size="medium"
                color="primary"
                onClick={() => handleModalOpen(row)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            {/* Delete Icon */}
            <Tooltip title="Delete">
              <IconButton
                size="large"
                color="error"
                onClick={() => handleDeleteDialog(row.id)}
              >
                <img src={deleteIcon} />
              </IconButton>
            </Tooltip>
            {/* View Document Password Icon */}
            {(userType === "super_admin" || userType === "admin") && <Tooltip title="View Document Password">
              <IconButton
                size="large"
                color="primary"
                onClick={() => handleViewDocumentConfirmationModal(row)}
                disabled={!row?.imageUrl}
              >
                <Iconify icon="carbon:document-view" width={24} />
              </IconButton>
            </Tooltip>}
            {/* View Document Icon */}
            {(userType === "super_admin" || userType === "admin") && <Tooltip title="View Document">
              <IconButton
                size="large"
                color="primary"
                onClick={() => {
                  const documentUrl = `${process.env.REACT_APP_API_BASE_IMAGE_URL}/customer/${row?.imageUrl}`;
                  window.open(documentUrl, "_blank");
                }}
                disabled={!row?.imageUrl}
              >
                <Iconify icon="carbon:document-blank" width={24} />
              </IconButton>
            </Tooltip>}
            {/* Confirmation Modal */}
            <ConfirmationModal
              open={openDeleteDialog.status}
              onClose={handleDeleteClose}
              onConfirm={handleConfirmDelete}
              iconName="customer"
            />
          </Stack>
        );
      },
    },
  ];

  return (
    <div>
      <Container
        sx={{
          paddingX: "8px !important",
          marginX: "auto !important",
          maxWidth: "100% !important",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
          sx={{ border: "", ...(isSmallScreen && { marginTop: 2 }) }}
        >
          <Typography variant="h5" gutterBottom color={palette.primary.light}>
            Customer
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleModalOpen}
          >
            New Customer
          </Button>
        </Stack>
        <Card sx={{ mt: 3, overflowX: "auto" }}>
          <SearchInput
            sx={{ ml: 1, mt: 1 }}
            label="customer"
            value={searchCustomerValue}
            onChange={(e) => {
              setSearchCustomerValue(e.target.value);
            }}
            onClear={() => {
              setSearchCustomerValue("");
            }}
          />
          <DataGrid
            rows={customer?.filter((cust) =>
              Object.values(cust).some(
                (value) =>
                  value !== null &&
                  value
                    ?.toString()
                    .toLowerCase()
                    .includes(searchCustomerValue.toLowerCase())
              )
            )}
            columns={columns}
            /* initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10, 50]} */
            hideFooterPagination
            disableRowSelectionOnClick
            autoHeight
            localeText={{ noRowsLabel: "No Customer data available" }}
            sx={{
              "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-row": {
                position: "relative",
              },
              // header-bottom
              "& .MuiDataGrid-row:first-child::before": {
                content: "''",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // each-row-bottom
              "& .MuiDataGrid-row::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // footer-bottom
              "& .MuiDataGrid-row:last-child::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              minWidth: "678px",
              width: "100%",
              overflowX: "auto",
            }}
          />
        </Card>
        {/* Server Pagination */}
        <ServerPagination
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          prevPage={prevPage}
          nextPage={nextPage}
          data={customer}
          totalSize={totalSize}
        />

        <Drawer anchor="right" open={modalOpen} onClose={handleModalClose}>
          <Container maxWidth="sm">
            <Box
              sx={{
                bgcolor: "background.paper",
                width: 400,
                marginTop: "40px",
                ...(isSmallScreen && { width: 280 }),
              }}
            >
              <IconButton
                sx={{ position: "absolute", top: 5, right: 5 }}
                color={palette.grey[500]}
                onClick={handleModalClose}
              >
                <ClearIcon />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                <Typography variant="h6" gutterBottom mb={3}>
                  {modalMode === "add" ? "Add Customer" : "Edit Customer"}
                </Typography>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="First Name *"
                      name="customerFirstName"
                      type="text"
                      {...formik.getFieldProps("customerFirstName")}
                    ></TextField>
                    {formik.touched.customerFirstName &&
                      formik.errors.customerFirstName ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.customerFirstName}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Last Name *"
                      name="customerLastName"
                      type="text"
                      {...formik.getFieldProps("customerLastName")}
                    ></TextField>
                    {formik.touched.customerLastName &&
                      formik.errors.customerLastName ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.customerLastName}
                      </Typography>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Contact Number *"
                      name="customerContactNumber"
                      type="text"
                      {...formik.getFieldProps("customerContactNumber")}
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleContactNumberChange(e.target.value);
                      }}
                    ></TextField>
                    {formik.touched.customerContactNumber &&
                      formik.errors.customerContactNumber ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.customerContactNumber}
                      </Typography>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Gender"
                      name="customerGender"
                      select
                      value={formik.values.customerGender}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value={"male"}>Male</MenuItem>
                      <MenuItem value={"female"}>Female</MenuItem>
                      <MenuItem value={"other"}>Other</MenuItem>
                    </TextField>

                    {formik.touched.customerGender &&
                      formik.errors.customerGender ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.customerDocumentType}
                      </Typography>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Age Category"
                      name="customerAgeCategory"
                      select
                      value={formik.values.customerAgeCategory}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value={"adult"}>Adult</MenuItem>
                      <MenuItem value={"child"}>Child</MenuItem>
                    </TextField>

                    {formik.touched.customerAgeCategory &&
                      formik.errors.customerAgeCategory ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.customerAgeCategory}
                      </Typography>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Document Type"
                      name="customerDocumentType"
                      select
                      value={formik.values.customerDocumentType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value={"votor"}>Voter Id</MenuItem>
                      <MenuItem value={"addhar"}>Adhaar Card</MenuItem>
                    </TextField>

                    {formik.touched.customerDocumentType &&
                      formik.errors.customerDocumentType ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.customerDocumentType}
                      </Typography>
                    ) : null}
                  </Grid>
                  {/* <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Id Number"
                      name="customerIdNumber"
                      type="text"
                      {...formik.getFieldProps("customerIdNumber")}
                    ></TextField>
                    {formik.touched.customerIdNumber &&
                    formik.errors.customerIdNumber ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.customerIdNumber}
                      </Typography>
                    ) : null}
                  </Grid> */}
                  {modalMode === "edit" && editedCustomer?.imageUrl ? (
                    <Grid item xs={12} sm={6}>
                      <Button
                        component="label"
                        variant="outlined"
                        startIcon={<FileOpenIcon />}
                        sx={{ fontSize: 12 }}
                        onClick={() => {
                          const documentUrl = `${process.env.REACT_APP_API_BASE_IMAGE_URL}/customer/${editedCustomer?.imageUrl}`;
                          window.open(documentUrl, "_blank");
                        }}
                      >
                        View Document
                      </Button>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={12}>
                    <Button
                      component="label"
                      variant="outlined"
                      startIcon={<CloudUploadIcon />}
                      sx={{ fontSize: 12 }}
                    >
                      Upload Document
                      <input
                        key={fileKey}
                        className="customer-file-upload-input"
                        type="file"
                        multiple
                        onChange={handleFileChange}
                      />
                    </Button>
                    {selectedFiles.length > 0 && (
                      <div style={{ marginTop: 10 }}>
                        {selectedFiles.map((file, index) => (
                          <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                            <IconButton
                              sx={{ position: 'relative' }}
                              size="small"
                              color="error"
                              onClick={() => { removeFile(file); setFileKey(Date.now()) }}
                            >
                              <ClearIcon />
                            </IconButton>
                            <Button
                              size="small"
                              variant="text"
                              sx={{ ml: 2 }}
                              onClick={() => viewFile(file)}
                            >
                              View
                            </Button>
                            <Typography sx={{ display: 'inline-block', ml: 1 }}>
                              {file.name}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    )}
                    <br />
                    <Typography variant="caption" color="primary">document should be only in png/jpg/jpeg/gif format</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="IsActive"
                      name="customerIsActive"
                      select
                      value={formik.values.customerIsActive}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </TextField>
                    {formik.touched.customerIsActive &&
                      formik.errors.customerIsActive ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.customerIsActive}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
                <Box
                  my={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button type="submit" variant="contained" color="primary">
                    {modalMode === "add" ? "Add" : "Update"}
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    sx={{
                      backgroundColor: palette.grey[500],
                      "&:hover": {
                        backgroundColor: palette.grey[600],
                      },
                      marginLeft: 1,
                    }}
                    onClick={() => formik.resetForm()}
                  >
                    clear
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Drawer>
      </Container>

      {isDuplicateModalOpen && (
        <Dialog open={isDuplicateModalOpen} onClose={handleCloseDuplicateModal}>
          <DialogTitle>Duplicate Mobile Number</DialogTitle>
          <DialogContent>
            <Typography>
              A customer with this mobile number already exists. Are you sure
              you want to proceed?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDuplicateModal} color="primary">
              No
            </Button>
            <Button onClick={handleConfirmDuplicate} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {viewDocumentConfirmationModal &&
        <Dialog
          open={viewDocumentConfirmationModal}
          onClose={handleCloseDocumentConfirmationModal}
        >
          <DialogTitle>View Document</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure to view document password?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDocumentConfirmationModal}
              color="primary">
              No
            </Button>
            <Button
              onClick={handleDocumentViewOTP}
              color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      }

      <ShowDocumentPasswordModal
        viewDocumentOtpModal={viewDocumentOtpModal}
        handleCloseDocumentOTPmodal={handleCloseDocumentOTPmodal}
        // handleDocumentViewOTP={handleDocumentViewOTP}
        documentData={documentData}
      />
    </div>
  );
};

export default Customer;
