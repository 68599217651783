import DashboardIcon from "@mui/icons-material/Dashboard";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import TaskIcon from "@mui/icons-material/Task";
import GroupsIcon from "@mui/icons-material/Groups";
import BusinessIcon from "@mui/icons-material/Business";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import PersonIcon from "@mui/icons-material/Person";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import ReceiptIcon from "@mui/icons-material/Receipt";
// components
import {
  Dashboard,
  Branches,
  Floor,
  ServiceCategory,
  Rooms,
  Service,
  User,
  Team,
  Checkout,
  Customer,
  Taskreport,
  RoomType,
} from "../../../pages";

const navConfigSupervisor = [
  {
    title: "dashboard",
    path: "/app/dashboard",
    icon: <DashboardIcon />,
    element: <Dashboard />,
  },
  {
    title: "Customer",
    path: "/app/customer",
    icon: <SupportAgentIcon />,
    element: <Customer />,
  },
  {
    title: "Check-in",
    path: "/app/checkin",
    icon: <TransferWithinAStationIcon />,
    element: <Checkout />,
  },
  {
    title: "Setup",
    path: "/app/floor",
    element: <Floor />,
    icon: <RoomPreferencesIcon />,
    nested: [
      {
        title: "Floor",
        path: "/app/floor",
        icon: <BusinessIcon />,
        element: <Floor />,
      },
      {
        title: "Room",
        path: "/app/room",
        icon: <BedroomParentIcon />,
        element: <Rooms />,
      },
      {
        title: "RoomType",
        path: "/app/roomtype",
        icon: <BedroomParentIcon />,
        element: <RoomType />,
      },
      {
        title: "Service Category",
        path: "/app/service-category",
        icon: <TransferWithinAStationIcon />,
        element: <ServiceCategory />,
      },
      {
        title: "Service",
        path: "/app/service",
        icon: <MiscellaneousServicesIcon />,
        element: <Service />,
      },
      {
        title: "Team",
        path: "/app/team",
        icon: <GroupsIcon />,
        element: <Team />,
      },
    ],
  },
  {
    title: "User",
    path: "/app/user",
    icon: <PersonIcon />,
    element: <User />,
  },
  {
    title: "Service Request Report",
    path: "/app/taskreport",
    icon: <TaskIcon />,
    element: <Taskreport />,
  },
  {
    title: "Invoice",
    path: "/app/invoice",
    icon: <ReceiptIcon />,
  },
];

export default navConfigSupervisor;
