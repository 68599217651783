import React from 'react';
import { Box, Divider, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const GuestInfoModal = ({ isOpen, onClose, guestData }) => {
    return (
        <>
            <Modal
                open={isOpen}
                onClose={onClose}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        py: 2,
                        px: 3,
                        borderRadius: 1,
                        // maxHeight: "80vh",
                        height: "450px",
                        width: "500px",
                        overflowY: "auto",
                    }}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1">
                            {`Guest Information for ${guestData?.customer?.firstName} ${guestData?.customer?.lastName}`}
                        </Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box mt={2}>
                        {guestData && guestData?.customerCheckoutJunction?.map((guest) => (
                            <div key={guest.id}>
                                <Typography>Name: {`${guest.customer?.firstName} ${guest.customer?.lastName}`}</Typography>
                                <Typography>Contact Number: {guest.customer?.contactNumber}</Typography>
                                <Typography>Gender: {guest.customer?.gender}</Typography>
                                <Typography>Age Category: {guest.customer?.ageCategory}</Typography>
                                <Divider sx={{ my: 1 }} />
                            </div>
                        ))}
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default GuestInfoModal;