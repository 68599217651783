import axiosInstance from ".";

export const getRoomTypeByBranch = async (branchId) => {
    try {
        const response = await axiosInstance.get(`/admin/roomtype?branchId=${branchId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const addRoomType = async (roomTypeDetails) => {
    try {
        const response = await axiosInstance.post('/admin/roomtype', roomTypeDetails);
        return response.data
    } catch (error) {
        throw error
    }
}

export const updateRoomType = async (roomTypeId, roomTypeDetails) => {
    try {
        const response = await axiosInstance.put(`/admin/roomtype/${roomTypeId}`, roomTypeDetails);
        return response.data
    } catch (error) {
        throw error
    }
}

export const deleteRoomType = async (roomTypeId) => {
    try {
        const response = await axiosInstance.delete(`/admin/roomtype/${roomTypeId}`)
        return response.data
    } catch (error) {
        throw error
    }
}