import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import { useClient } from "../../context/ClientProvider";
import SearchIcon from "@mui/icons-material/Search";
import InvoiceStatusAlert from "../InvoiceStatusAlert";

const HeaderInfo = () => {
  const localUserType = localStorage.getItem("userType");
  const userType = JSON.parse(localUserType);
  const {
    activeGlobalClient,
    activeGlobalBranch,
    globalClientList,
    globalBranchList,
    updateActiveGlobalClient,
    updateActiveGlobalBranch,
  } = useClient();

  const InvoiceAlertProps = {
    activeGlobalBranch: activeGlobalBranch,
    userType: userType,
  };
  return (
    <>
      <InvoiceStatusAlert {...InvoiceAlertProps} />

      <Box
        className="customAutocomplete"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          "@media (min-width: 600px)": {
            flexDirection: "row", // Change direction to row for wider screens
          },
        }}
      >
        <Grid spacing={2} item xs={12} sm={6} width={280}>
          <Autocomplete
            options={globalClientList ? globalClientList : []}
            getOptionLabel={(client) => client.name}
            onChange={(event, clientValue) => {
              updateActiveGlobalClient(clientValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Client"
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <SearchIcon
                      style={{
                        marginLeft: "6px",
                        color: "rgba(0, 0, 0, 0.34)",
                      }}
                    />
                  ),
                }}
              />
            )}
            noOptionsText="No clients"
            value={activeGlobalClient}
            disabled={
              userType === "admin" || (userType === "supervisor" || userType === "frontdesk" && true)
            }
            disableClearable
          />
        </Grid>
        <Grid item xs={12} sm={6} width={280}>
          <Autocomplete
            options={globalBranchList ? globalBranchList : []}
            getOptionLabel={(branch) => branch.name}
            onChange={(event, branchValue) => {
              updateActiveGlobalBranch(branchValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Branch"
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <SearchIcon
                      style={{
                        marginLeft: "6px",
                        color: "rgba(0, 0, 0, 0.34)",
                      }}
                    />
                  ),
                }}
              />
            )}
            noOptionsText="No branches"
            value={activeGlobalBranch}
            disabled={userType === "supervisor" || userType === "frontdesk" && true}
            disableClearable
            sx={{
              marginLeft: "0px",
              marginY: "3px",
              "@media (min-width: 600px)": {
                marginLeft: "6px",
                marginY: 0,
              },
            }}
          />
        </Grid>
      </Box>
    </>
  );
};

export default HeaderInfo;
