import React, { useState, useEffect } from 'react'
import AuthPageWrapper from '../../components/AuthPageWrapper';

import { Box, Button, TextField, Typography, InputAdornment, CircularProgress } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { adminLogin } from '../../api/auth';
import LoginLogo from '../../assets/images/Login_logo.png';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useAuth } from '../../context/AuthProvider';
import { getBranchByClient, getBranchById } from '../../api/branch';
import { useClient } from '../../context/ClientProvider';

const loginValidationSchema = Yup.object().shape({
    userName: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
})

const AdminLogin = () => {
    const { setGlobalUser } = useAuth();
    // console.log("location:", location);
    const { setGlobalClientList, setActiveGlobalClient, setGlobalBranchList, setActiveGlobalBranch } = useClient();

    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigateTo = localStorage?.getItem("adminAppPath")?.replace(/^"|"$/g, '');
    // console.log("admin navigateTo", navigateTo);

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const formik = useFormik({
        initialValues: {
            userName: '',
            password: '',
        },
        validationSchema: loginValidationSchema,
        onSubmit: async (values) => {
            setIsLoading(true);
            const adminCredentials = {
                userName: values.userName,
                password: values.password,
                clientId: localStorage.getItem("clientId"),
            };
            try {
                const response = await adminLogin(adminCredentials);
                if (response.userType === 'staff') {
                    toast.error("You are not allowed to login this app")
                } else {
                    if (response) {
                        setGlobalUser((prevState) => { return { ...prevState, status: true, user: response } });
                        // localStorage.setItem("admin", JSON.stringify(response));
                        localStorage.setItem("user", JSON.stringify(response));
                        localStorage.setItem("token", JSON.stringify(response.token));
                        localStorage.setItem("refreshToken", response.refereshToken);
                        localStorage.setItem("userType", JSON.stringify(response.userType));
                        localStorage.setItem("clientId", JSON.stringify(response.clientId));
                        localStorage.setItem("branchIds", JSON.stringify(response.branchId));

                        // for admin login globalvalue
                        if (response.userType === 'admin') {
                            try {
                                const branchResponse = await getBranchByClient(response?.clientId);
                                localStorage.setItem("activeGlobalClient", JSON.stringify(response.client));
                                setActiveGlobalClient(response.client);
                                localStorage.setItem("globalBranchList", JSON.stringify(branchResponse));
                                setGlobalBranchList(branchResponse);
                                // const filteredBranch = branchResponse.filter((branch) => branch.id === response?.branchId);
                                localStorage.setItem("activeGlobalBranch", JSON.stringify(branchResponse[0]));
                                setActiveGlobalBranch(branchResponse[0]);
                            } catch (error) {
                                console.error('Error fetching admin global client data: ', error);
                            }
                        } else if (response.userType === 'supervisor' || response.userType === 'frontdesk') {
                            try {
                                localStorage.setItem("activeGlobalClient", JSON.stringify(response.client));
                                setActiveGlobalClient(response.client);
                                const branchResponse = await getBranchById(response?.branchId);
                                localStorage.setItem("activeGlobalBranch", JSON.stringify(branchResponse));
                                setActiveGlobalBranch(branchResponse);
                            } catch (error) {
                                console.error(`Error fetching ${response.userType} global data: `, error);
                            }
                        }
                        navigateTo ? navigate(navigateTo, { replace: true }) : navigate("/app", { replace: true });
                        // navigate("/app", {replace: true});
                    }
                }
            } catch (error) {
                toast.error("Invalid Credentials");
            } finally {
                setIsLoading(false);
            }
        },
    });

    useEffect(() => {
        const user = localStorage.getItem("user");
        const userData = JSON.parse(user);
        userData?.userType === "admin" && navigate("/app/dashboard", { replace: true });
    }, []);
    return (
        <AuthPageWrapper>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', background: '#fff', borderRadius: '32px', padding: '20px', boxShadow: '0px 4px 40px 0px #DC419C1A' }}>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', mb: 5 }}>
                    <div style={{ textAlign: 'left' }} >
                        <Box sx={{ my: '1.5rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img src={LoginLogo} width={200} height={45} alt="login_logo" />
                            <Typography variant='h4' sx={{ fontWeight: 500, fontSize: '1.4rem !important', color: '', mb: '6px', mt: 2 }}>Sign in</Typography>
                            {/* <Typography variant='body1' sx={{ color: 'rgba(47, 43, 61, 0.68)', fontSize: '0.9375rem' }}>Please sign-in to your account and start the adventure</Typography> */}
                        </Box>
                        <form onSubmit={formik.handleSubmit}>
                            <Box sx={{ mb: { xs: 4, xl: 3 }, boxShadow: '0px 18px 30px 0px #4461F21C' }}>
                                {/* <Typography variant="subtitle2" sx={{ color: 'rgba(47, 43, 61, 0.78)' }}>User Name</Typography> */}
                                <TextField
                                    id="outlined-userid-input"
                                    type="text"
                                    autoComplete="off"
                                    {...formik.getFieldProps('userName')}
                                    size="small"
                                    sx={{
                                        width: '100%',
                                    }}
                                    inputProps={{ style: { height: '21px' } }}
                                    placeholder='Username'
                                />
                                {formik.touched.userName && formik.errors.userName ? (
                                    <Typography sx={{ color: 'red', variant: 'body2', fontSize: '12px' }}>{formik.errors.userName}</Typography>
                                ) : null}
                            </Box>
                            <Box sx={{ mb: { xs: 2, xl: 3 }, boxShadow: '0px 18px 30px 0px #4461F21C' }}>
                                {/* <Typography variant="subtitle2" sx={{ color: 'rgba(47, 43, 61, 0.78)' }}>Password</Typography> */}
                                <TextField
                                    // id="outlined-password-input"
                                    // label="Password"
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="off"
                                    {...formik.getFieldProps('password')}
                                    size="small"
                                    placeholder='Password'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {!showPassword ? (
                                                    <Visibility onClick={handleTogglePassword} />
                                                ) : (
                                                    <VisibilityOff onClick={handleTogglePassword} />
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        width: '100%',
                                    }}
                                    inputProps={{ style: { height: '21px' } }}
                                />
                                {formik.touched.password && formik.errors.password ? (
                                    <Typography sx={{ color: 'red', variant: 'body2', fontSize: '12px' }}>{formik.errors.password}</Typography>
                                ) : null}
                            </Box>

                            <Box sx={{ justifyContent: 'end', display: 'flex', mb: { xs: 3, xl: 4 } }}>
                                <Link style={{ textDecoration: 'none' }} to='forgot-password'>
                                    <Typography variant="subtitle1" sx={{ color: 'secondary.main' }}>Forgot Password?</Typography>
                                </Link>
                            </Box>

                            <div>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={isLoading}
                                    sx={{
                                        // minWidth: 200,
                                        width: '100%',
                                        padding: '0.625rem 1.25rem',
                                        backgroundColor: 'secondary.main',
                                        // borderRadius: '6px',
                                        typography: "btnPrimary"
                                    }}
                                >{isLoading ? (
                                    <CircularProgress size={24} sx={{ mr: 1 }} color="inherit" />
                                ) : (
                                    'Login'
                                )}
                                </Button>
                            </div>
                        </form>
                    </div>
                </Box>
            </Box>
        </AuthPageWrapper>
    )
}

export default AdminLogin