import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  TextField,
  Avatar,
  Input,
  Button,
  Container,
  Grid,
  InputAdornment,
  Link
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginUserType } from "../../utils/userType";
import toast from "react-hot-toast";
import { getUser, updateUser } from "../../api/user";
import { getSuperadmin, updateSuperadmin } from "../../api/superadmin";
import EditIcon from "../../assets/icons/EditIcon";

const Profile = () => {
  const [users, setUsers] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
    email: "",
    file: null,
  });
  const [avatarUrl, setAvatarUrl] = useState(null);

  // extracting user type of the user (superadmin/admin/supervisor) from local storage 
  const localUserType = localStorage.getItem("userType");
  const userType = JSON.parse(localUserType);

  // extracting login Id of the user (superadmin/admin/supervisor) from local storage 
  const userLogin = localStorage.getItem("user");
  const userLoginType = JSON.parse(userLogin);
  const userLoginId = userLoginType?.userId;

  useEffect(() => {
    (async () => {
      try {
        if (userType === loginUserType.SUPER_ADMIN) {
          const response = await getSuperadmin(userLoginId);
          if (response) {
            setUsers((prevValue) => ({
              ...prevValue,
              userName: response.userName,
              firstName: response.firstName,
              lastName: response.lastName,
              contactNumber: response.contactNumber,
              email: response.email,
              file: response.imageUrl,
            }));
          }
        } else if (
          userType === loginUserType.ADMIN ||
          userType === loginUserType.SUPERVISOR ||
          userType === loginUserType.FRONTDESK
        ) {
          const response = await getUser(userLoginId);
          if (response) {
            setUsers((prevValue) => ({
              ...prevValue,
              userName: response.userName,
              firstName: response.firstName,
              lastName: response.lastName,
              contactNumber: response.contactNumber,
              email: response.email,
              file: response.imageUrl
            }));
          }
        }
      } catch (error) {
        // console.log("error in fetching user profile", error);
      }
    })();
  }, [userType]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userDetail = {
      firstName: users.firstName,
      lastName: users.lastName,
      contactNumber: users.contactNumber,
      email: users.email,
      ...(users.file?.name && { file: users.file })
    }

    try {
      if (userType === loginUserType.SUPER_ADMIN) {
        users.type = "super_admin";
        const response = await updateSuperadmin(
          userLoginId,
          userDetail
        );
        if (response) {
          setUsers((previousUsers) => ({
            ...previousUsers,
            firstName: users.firstName,
            lastName: users.lastName,
            contactNumber: users.contactNumber,
            email: users.email,
            file: users.file,
          }));
          toast.success(`superadmin updated successfully`);
        }
      } else if (
        userType === loginUserType.ADMIN || userType === loginUserType.SUPERVISOR || userType === loginUserType.FRONTDESK) {
        const response = await updateUser(userLoginId, userDetail);
        if (response) {
          setUsers((previousUsers) => ({
            ...previousUsers,
            firstName: users.firstName,
            lastName: users.lastName,
            contactNumber: users.contactNumber,
            email: users.email,
            file: users.file,
          }));
          toast.success(`${userType} updated successfully`);
        }
      }
    } catch (error) {
      toast.error(error.response?.data?.message?.message[0]);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "centre",
          paddingLeft: "10px",
        }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              bgcolor: "background.paper",
              width: "auto",
              marginTop: "40px",
              boxShadow: 10,
              padding: 5,
              borderRadius: 5,
            }}
          >
            <form onSubmit={handleSubmit}>
              <Box sx={{ textAlign: 'center' }}>
                <Avatar
                  alt="User Avatar"
                  src={userType === loginUserType.SUPER_ADMIN ?
                    avatarUrl || `${process.env.REACT_APP_API_BASE_IMAGE_URL}/superAdmin/${users.file}` :
                    avatarUrl || `${process.env.REACT_APP_API_BASE_IMAGE_URL}/user/${users.file}`}
                  sx={{ width: 70, height: 70, borderRadius: '50%', mx: 'auto', mb: 2 }}
                  onClick={() => {
                    document.getElementById('userImageFile').click();
                  }}
                />
                <Input
                  type="file"
                  accept="image/*"
                  id="userImageFile"
                  name="imageUrl"
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    const file = event.target.files[0];
                    setUsers((prevValue) => ({
                      ...prevValue,
                      file: file,
                    }));
                    const fileUrl = URL.createObjectURL(file);
                    setAvatarUrl(fileUrl);
                  }}
                />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="User name"
                    name="userName"
                    value={users.userName}
                    disabled={true}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="userFirstName"
                    type="text"
                    value={users.firstName}
                    onChange={(e) => setUsers(prevState => ({ ...prevState, firstName: e.target.value }))}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="LastName"
                    name="lastName"
                    type="text"
                    value={users.lastName}
                    onChange={(e) => setUsers(prevState => ({ ...prevState, lastName: e.target.value }))}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Contact Number"
                    name="contactNumber"
                    type="number"
                    disabled={userType === "admin"}
                    value={users.contactNumber}
                    onChange={(e) => setUsers(prevState => ({ ...prevState, contactNumber: e.target.value }))}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    value={users.email}
                    onChange={(e) => setUsers(prevState => ({ ...prevState, email: e.target.value }))}
                  ></TextField>
                </Grid>
              </Grid>
              <Box
                mt={5}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginRight: 1,
                }}
              >
                <Button type="submit" variant="contained" color="primary">
                  Update details
                </Button>
              </Box>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Profile;
