import React, { useEffect, useState } from "react";
import {
  Button,
  Stack,
  Box,
  Autocomplete,
  Grid,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useIsSmallScreen } from "../../hooks/useResponsive";
import moment from "moment";
import { getRoomByBranch } from "../../api/rooms";
import { getCheckoutByRoom } from "../../api/checkout";
import ClearIcon from "@mui/icons-material/Clear";

const CheckoutDataFilter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  openEndDatePicker,
  setOpenEndDatePicker,
  getCheckouts,
  handleExport,
  activeGlobalBranch,
  setCustomerCheckout,
}) => {
  const isSmallScreen = useIsSmallScreen();
  const [availableRooms, setAvailableRooms] = useState([]);
  const [roomSelected, setRoomSelected] = useState("");

  const getAvailableRooms = async () => {
    setRoomSelected("");
    const response = await getRoomByBranch(activeGlobalBranch?.id);
    setAvailableRooms(response);
  };
  const getCheckoutsByRoom = async () => {
    if (roomSelected) {
      const response = await getCheckoutByRoom(roomSelected?.id);
      setCustomerCheckout(response);
    }
  };
  useEffect(() => {
    getCheckoutsByRoom();
  }, [roomSelected]);

  useEffect(() => {
    getAvailableRooms();
  }, [activeGlobalBranch]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          ...(isSmallScreen && {
            flexDirection: "column",
            alignItems: "flex-start",
          }),
        }}
      >
        <Stack spacing={2} alignItems="left">
          <Stack direction="row" spacing={2} alignItems="center">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setOpenEndDatePicker(true);
                }}
                slotProps={{ textField: { size: "small" } }}
                sx={{ width: 200, ...(isSmallScreen && { width: 150 }) }}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                open={openEndDatePicker}
                onOpen={() => setOpenEndDatePicker(true)}
                onClose={() => setOpenEndDatePicker(false)}
                slotProps={{ textField: { size: "small" } }}
                sx={{ width: 200, ...(isSmallScreen && { width: 150 }) }}
              />
            </LocalizationProvider>
            {/* <Button
                    variant="contained"
                    color="primary"
                    sx={{ height: 30 }}
                    onClick={() => checkoutData()}
                >
                    Find
                </Button> */}
            <Button
              sx={{ height: 30 }}
              variant="contained"
              onClick={() => {
                /* setStartDate(null);
              setEndDate(null); */
                setStartDate(moment().subtract(7, "days").startOf("day"));
                setEndDate(moment().endOf("day"));
              }}
            >
              Reset
            </Button>
          </Stack>

          <Autocomplete
            size="small"
            sx={{ width: 200 }}
            options={availableRooms}
            getOptionLabel={(option) => option?.name || ""}
            value={roomSelected}
            onChange={(event, roomValue, isClear) => {
              if (isClear === "clear") {
                getCheckouts();
              }
              setRoomSelected(roomValue);
            }}
            //onChange={(event, value) => setRoomSelected(value)}
            renderInput={(params) => <TextField {...params} label="Room" />}
          ></Autocomplete>
        </Stack>
        <Box paddingRight={2}>
          <Button
            variant="contained"
            color="primary"
            sx={{ height: 30, ...(isSmallScreen && { mt: 1 }), marginRight: 1 }}
            onClick={handleExport}
          >
            Export
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ height: 30, ...(isSmallScreen && { mt: 1 }) }}
            onClick={() => {
              getCheckouts();
            }}
          >
            Refresh
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default CheckoutDataFilter;
